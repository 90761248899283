import { useLocalStore } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { StoreState } from '../../types';
import { createMainStore } from '../../../bk/stores';
import { createMkAnalisisStore } from '../../../bk/components/main/components/mortalKombat/analisis/store';
import { createMkMainStore } from '../../../bk/components/main/components/mortalKombat/store';
import { createMkStatiscticsStore } from '../../../bk/components/main/components/mortalKombat/statistics/store';

type StoreProviderProps = {
  children?: React.ReactNode;
};

export const StoreProvider: React.FC = ({ children }: StoreProviderProps) => {
  const mainStore = useLocalStore(createMainStore);

  const mkMainStore = useLocalStore(createMkMainStore);
  const mkStatisticsStore = useLocalStore(createMkStatiscticsStore);
  const mkAnalisisStore = useLocalStore(createMkAnalisisStore);

  const store = useMemo(
    () => ({ mainStore, mkMainStore, mkStatisticsStore, mkAnalisisStore }),
    [mainStore, mkMainStore, mkStatisticsStore, mkAnalisisStore]
  );

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const storeContext = React.createContext<StoreState | null>(null);

export default StoreProvider;
