import React from 'react';

import { Button } from 'primereact/button';

import PairSelection from '../pair-selection';
import SelectionParameters from '../selection-parameters';
import css from './index.module.css';

const SettingsContent: React.FC = () => {
  return (
    <>
      <PairSelection />
      <SelectionParameters />

      <div className={css.buttonsLayout}>
        <Button
          className={css.settingsButton}
          icon="pi pi-refresh"
          label="Обновить данные"
          style={{ marginRight: '.25em' }}
        />
        <Button
          className={css.settingsButton}
          icon="pi pi-reply"
          label="Статистика пары"
          style={{ marginRight: '.25em' }}
        />
        <Button
          className={css.settingsButton}
          icon="pi pi-times"
          label="Сбросить фильтры"
          style={{ marginRight: '.25em' }}
        />
      </div>
    </>
  );
};

export default SettingsContent;
