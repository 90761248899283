/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { getSVG } from '../../../icons';
import { useRootData } from '../../../../core/hooks/use-root-data';
import css from '../index.module.css';

const Theme = () => {
  const { currentColorTheme, setColorTheme } = useRootData((store) => ({
    currentColorTheme: store.mainStore.currentColorTheme,
    setColorTheme: store.mainStore.setColorTheme
  }));
  const handleSwitchTheme = () => {
    setColorTheme(currentColorTheme === 'light' ? 'dark' : 'light');
  };
  return (
    <li className={css.navItem}>
      <a
        className={css.navLink}
        onClick={handleSwitchTheme}
        role="button"
        tabIndex={0}
        type="button"
      >
        {currentColorTheme === 'light' ? (
          <>
            {getSVG('darkTheme')}
            <span className={css.linkText}>Dark theme</span>
          </>
        ) : (
          <>
            {getSVG('lightTheme')}
            <span className={css.linkText}>Light theme</span>
          </>
        )}
      </a>
    </li>
  );
};

export default Theme;
