/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';

import { ColumnGroup, MkHeroes, MkLeagues } from '../../types';

import {
  ClidOppsFilter,
  Coef1x2Filter,
  CoefRfbFilter,
  CoefRoundFilter,
  CoefRoundGroupFilter,
  CoefTotalBFilter,
  CoefTotalBGroupFilter,
  CoefTotalFFilter,
  CoefTotalFGroupFilter,
  CommonTotalFilter,
  CommonTotalGroupFilter,
  DateStartFilter,
  DurationFilter,
  DurationGroupFilter,
  FwFilter,
  FwGroupFilter,
  LeagueFilter,
  PairGroupFilter,
  ResultFilter,
  RoundFilter,
  RoundsGroupFilter,
  TimeAvgFilter,
  TimeMaxFilter,
  TimeMinFilter
} from '../filters';

import {
  CoefRoundRenderer,
  CoefTotalBRenderer,
  CoefTotalFRenderer,
  CommonTotalRenderer,
  DurationRenderer,
  FwRenderer,
  PairRenderer,
  ResultRenderer,
  RoundsRenderer
} from '../renderers';

import css from './index.module.css';

export const getStatsColumnsConfig = (heroes: MkHeroes, leagues: MkLeagues) => {
  const infoColumns = {
    groupHeader: 'Информация',
    groupField: '_information',
    groupFilter: null,
    groupVisibility: true,
    groupClassName: 'dateStartCell',
    columns: [
      {
        header: '#пп',
        field: 'n',
        width: '2rem',
        filter: null,
        visibility: true,
        renderer: (rowData: any) => {
          return rowData.n?.v;
        }
      },

      {
        header: '#',
        field: 'naprav',
        width: '2rem',
        filter: null,
        visibility: true,
        renderer: (rowData: any) => {
          return (
            <i
              className={cn(
                'pi pi-circle-on',
                css.napravCellContent,
                css[`naprav_${rowData.naprav?.v}` as keyof typeof css]
              )}
            />
          );
        }
      },
      {
        header: 'A',
        field: 'analisis',
        width: '2rem',
        filter: null,
        visibility: true,
        renderer: () => {
          return <i className="pi pi-external-link" />;
        }
      },
      {
        header: 'Дата и время',
        field: 'dateStart',
        width: '7rem',
        visibility: true,
        filter: <DateStartFilter />,
        renderer: (rowData: any) => rowData.dateStart?.v
      },
      {
        header: 'Лига',
        field: 'champId',
        width: '5rem',
        visibility: true,
        filter: <LeagueFilter />,
        renderer: (rowData: any) => {
          return leagues[rowData.champId?.v];
        }
      }
    ]
  };

  const pairColumns = {
    groupHeader: 'Пара',
    groupField: 'clid_opps',
    groupVisibility: true,
    groupClassName: 'heroCell',
    groupFilter: <PairGroupFilter />,
    columns: [
      { key: '1', header: 'Левый' },
      { key: '2', header: 'Правый' }
    ].map(({ key, header }) => {
      const field = `clid_opp${key}`;
      return {
        field,
        header,
        width: '8rem',
        visibility: true,
        filter: <ClidOppsFilter field={field} header={header} />,
        renderer: (rowData: any) => (
          <PairRenderer field={field} rowData={rowData} />
        )
      };
    })
  };

  const resultColumn = {
    groupHeader: 'Рез.',
    groupField: '_result',
    groupVisibility: true,
    groupClassName: 'heroCell',
    groupFilter: null,
    columns: [
      {
        header: 'Рез.',
        field: 'result',
        width: '3rem',
        visibility: true,
        filter: <ResultFilter />,
        renderer: (rowData: any) => <ResultRenderer rowData={rowData} />
      }
    ]
  };

  const roundsColumns = {
    groupHeader: 'Раунды',
    groupField: '_rounds',
    groupVisibility: true,
    groupClassName: 'roundCell',
    groupFilter: <RoundsGroupFilter />,
    columns: Array.from({ length: 9 }, (_, key) => `${key + 1}`).map(
      (col: string) => {
        const field = `r${col}`;
        return {
          field,
          header: col,
          width: '3rem',
          visibility: true,
          filter: <RoundFilter field={field} />,
          renderer: (rowData: any) => (
            <RoundsRenderer field={field} rowData={rowData} />
          )
        };
      }
    )
  };

  const coefRoundColumns = {
    groupHeader: 'Кефы раунд',
    groupField: '_coefRound',
    groupVisibility: true,
    groupClassName: 'coefRoundCell',
    groupFilter: <CoefRoundGroupFilter />,
    columns: Array.from({ length: 2 }, (_, key) => `${key + 1}`).map(
      (col: string) => {
        const field = `sp${col}`;
        return {
          field,
          header: `П${col}`,
          width: '3rem',
          visibility: true,
          filter: <CoefRoundFilter field={col} />,
          renderer: (rowData: any) => (
            <CoefRoundRenderer field={field} rowData={rowData} />
          )
        };
      }
    )
  };

  const rfbColumns = {
    groupHeader: 'Кефы добив.',
    groupField: '_coefRfb',
    groupVisibility: true,
    groupClassName: 'coefRfbCell',
    groupFilter: null,
    columns: 'r-f-b'.split('-').map((col: string) => {
      const field = `s${col}r`;
      return {
        field,
        header: col.toUpperCase(),
        width: '3rem',
        visibility: true,
        filter: <CoefRfbFilter field={col} />,
        renderer: (rowData: any) => rowData[field].v
      };
    })
  };

  const durationColumns = {
    groupHeader: 'Продолжительность',
    groupField: '_duration',
    groupVisibility: true,
    groupClassName: 'durationCell',
    groupFilter: <DurationGroupFilter />,
    columns: Array.from({ length: 9 }, (value, key) => `${key + 1}`).map(
      (col: string) => {
        const field = `r${col}time`;
        return {
          field,
          header: col,
          width: '3rem',
          visibility: true,
          filter: <DurationFilter field={col} />,
          renderer: (rowData: any) => (
            <DurationRenderer field={field} rowData={rowData} />
          )
        };
      }
    )
  };

  const timeMinColumns = {
    groupHeader: 'Время мин.',
    groupField: '_timeMin',
    groupVisibility: true,
    groupClassName: 'timeMinCell',
    groupFilter: null,
    columns: [
      { key: '1m', header: 'М' },
      { key: 'p1m', header: 'Вр.' },
      { key: '1b', header: 'Б' }
    ].map(({ key, header }) => {
      const field = `time${key}`;
      return {
        field,
        header,
        width: '3rem',
        visibility: true,
        filter: <TimeMinFilter field={field} header={header} />,
        renderer: (rowData: any) => rowData[field].v
      };
    })
  };

  const timeAvgColumns = {
    groupHeader: 'Время сред.',
    groupField: '_timeAvg',
    groupVisibility: true,
    groupClassName: 'timeAvgCell',
    groupFilter: null,
    columns: [
      { key: '2m', header: 'М' },
      { key: 'p2m', header: 'Вр.' },
      { key: '2b', header: 'Б' }
    ].map(({ key, header }) => {
      const field = `time${key}`;
      return {
        field,
        header,
        width: '3rem',
        visibility: true,
        filter: <TimeAvgFilter field={field} header={header} />,
        renderer: (rowData: any) => rowData[field].v
      };
    })
  };

  const timeMaxColumns = {
    groupHeader: 'Время макс.',
    groupField: '_timeMax',
    groupVisibility: true,
    groupClassName: 'timeMaxCell',
    groupFilter: null,
    columns: [
      { header: 'М', key: '3m' },
      { header: 'Вр.', key: 'p3m' },
      { header: 'Б', key: '3b' }
    ].map(({ key, header }) => {
      const field = `time${key}`;
      return {
        field,
        header,
        width: '3rem',
        visibility: true,
        filter: <TimeMaxFilter field={field} header={header} />,
        renderer: (rowData: any) => rowData[field].v
      };
    })
  };

  const coefTotalFColumns = {
    groupHeader: 'Кефы тотал F',
    groupField: '_coefTotalF',
    groupVisibility: true,
    groupClassName: 'coefTotalFCell',
    groupFilter: <CoefTotalFGroupFilter />,
    columns: [
      { header: `0,5М`, key: `05m` },
      { header: `0,5Б`, key: `05b` },
      { header: `2,5М`, key: `25m` },
      { header: `2,5Б`, key: `25b` },
      { header: `4,5М`, key: `45m` },
      { header: `4,5Б`, key: `45b` }
    ].map(({ key, header }) => {
      const field = `tf${key}`;
      return {
        field,
        header,
        width: '3rem',
        visibility: true,
        filter: <CoefTotalFFilter field={field} header={header} />,
        renderer: (rowData: any) => (
          <CoefTotalFRenderer field={field} rowData={rowData} />
        )
      };
    })
  };

  const coefTotalBColumns = {
    groupHeader: 'Кефы тотал B',
    groupField: '_coefTotalB',
    groupVisibility: true,
    groupClassName: 'coefTotalBCell',
    groupFilter: <CoefTotalBGroupFilter />,
    columns: [
      { header: `0,5М`, key: `05m` },
      { header: `0,5Б`, key: `05b` },
      { header: `2,5М`, key: `25m` },
      { header: `2,5Б`, key: `25b` }
    ].map(({ key, header }) => {
      const field = `tb${key}`;
      return {
        field,
        header,
        width: '3rem',
        visibility: true,
        filter: <CoefTotalBFilter field={field} header={header} />,
        renderer: (rowData: any) => (
          <CoefTotalBRenderer field={field} rowData={rowData} />
        )
      };
    })
  };

  const commonTotalColumns = {
    groupHeader: 'Общий тотал',
    groupField: '_commonTotal',
    groupVisibility: true,
    groupClassName: 'commonTotalCell',
    groupFilter: <CommonTotalGroupFilter />,
    columns: [
      { header: `5,5М`, key: `55m` },
      { header: `5,5Б`, key: `55b` },
      { header: `6,5М`, key: `65m` },
      { header: `6,5Б`, key: `65b` },
      { header: `7,5М`, key: `75m` },
      { header: `7,5Б`, key: `75b` },
      { header: `8,5М`, key: `85m` },
      { header: `8,5Б`, key: `85b` }
    ].map(({ key, header }) => {
      const field = `t${key}`;
      return {
        field,
        header,
        width: '3rem',
        visibility: true,
        filter: <CommonTotalFilter field={field} header={header} />,
        renderer: (rowData: any) => (
          <CommonTotalRenderer field={field} rowData={rowData} />
        )
      };
    })
  };

  const coef1x2Config = {
    groupHeader: 'Кефы 1x2',
    groupField: '_coef1x2',
    groupVisibility: true,
    groupClassName: 'coef1x2Cell',
    groupFilter: null,
    columns: [
      { header: `П1`, key: `p1` },
      { header: `П2`, key: `p2` }
    ].map(({ key, header }) => {
      const field = `x2${key}`;
      return {
        field,
        header,
        width: '3rem',
        visibility: true,
        filter: <Coef1x2Filter field={field} header={header} />,
        renderer: (rowData: any) => rowData[field].v
      };
    })
  };

  const fwConfig = {
    groupHeader: 'ЧП',
    groupField: '_flawlessVictory',
    groupVisibility: true,
    groupClassName: 'fwCell',
    groupFilter: <FwGroupFilter />,
    columns: [
      { header: `ДА`, key: `scp` },
      { header: `0.5Б`, key: `tfw05b` }
    ].map(({ key, header }) => {
      const field = `${key}`;
      return {
        field,
        header,
        width: '3rem',
        visibility: true,
        filter: <FwFilter field={field} header={header} />,
        renderer: (rowData: any) => (
          <FwRenderer field={field} rowData={rowData} />
        )
      };
    })
  };

  const statsColumns: ColumnGroup[] = [
    infoColumns,
    pairColumns,
    resultColumn,
    roundsColumns,
    coefRoundColumns,
    rfbColumns,
    durationColumns,
    timeMinColumns,
    timeAvgColumns,
    timeMaxColumns,
    coefTotalFColumns,
    coefTotalBColumns,
    commonTotalColumns,
    coef1x2Config,
    fwConfig
  ];

  return statsColumns;
};
