import { BrowserRouter, Route, Switch } from 'react-router-dom';
import React from 'react';

import { Core } from './features/core';

const Router: React.FC = () => (
  <BrowserRouter basename="/">
    <Switch>
      <Route component={Core} path={['/index', '/']} />
    </Switch>
  </BrowserRouter>
);

export default Router;
