/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo } from 'react';

import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';
import css from './index.module.css';

const TotalStat = () => {
  const { dataForTotalStatTable } = useRootData((store) => ({
    dataForTotalStatTable: store.mkAnalisisStore.dataForTotalStatTable
  }));

  return (
    <div className={css.totalStatLayout}>
      <h4 className={css.totalStatPoint}>П1: {dataForTotalStatTable.sp1}</h4>
      <h4 className={css.totalStatPoint}>П2: {dataForTotalStatTable.sp2}</h4>
    </div>
  );
};

export default memo(TotalStat);
