/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import cn from 'classnames';

import { Sidebar } from 'primereact/sidebar';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from './index.module.css';

type SettingsSidebarProps = {
  title: string;
  children?: React.ReactNode;
};

const SettingsSidebar: React.FC<SettingsSidebarProps> = ({
  title,
  children
}: SettingsSidebarProps) => {
  const { isShowSettings, setIsShowSettings, isMobile } = useRootData(
    (store) => ({
      isShowSettings: store.mkAnalisisStore.isShowSettings,
      setIsShowSettings: store.mkAnalisisStore.setIsShowSettings,
      isMobile: store.mainStore.isMobile
    })
  );

  const handleHideSettings = () => setIsShowSettings('');

  return (
    <Sidebar
      blockScroll
      baseZIndex={0}
      className={cn(css.settingsSidebar, isMobile && css.settingsSidebarMobile)}
      dismissable={false}
      modal={false}
      onHide={handleHideSettings}
      position="bottom"
      showCloseIcon={false}
      visible={!!isShowSettings}
    >
      <div className={css.sidebarHeader}>
        <h3 className={css.sidebarTitle}>{title}</h3>
        <i
          className={cn('pi pi-times', css.sidebarCloseIcon)}
          onClick={handleHideSettings}
          role="button"
          tabIndex={0}
        />
      </div>
      {children}
    </Sidebar>
  );
};

export default SettingsSidebar;
