export function createMainStore() {
  const store = {
    isShownMenu: false as boolean,
    triggerMenuCollapse() {
      this.isShownMenu = !this.isShownMenu;
    },

    isCollapsedSider: false as boolean,
    triggerSiderColapse() {
      this.isCollapsedSider = !this.isCollapsedSider;
    },

    currentColorTheme: 'light',
    setColorTheme(theme: string) {
      document.documentElement.setAttribute('data-theme', theme);
      this.currentColorTheme = theme;
    },

    isPortrait: true,
    setIsPortrait(value: boolean) {
      this.isPortrait = value;
    },

    isMobile: true,
    setIsMobile(value: boolean) {
      this.isMobile = value;
    }
  };

  return store;
}

export type MainStoreType = ReturnType<typeof createMainStore>;
