/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { InputMask } from 'primereact/inputmask';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

const ResultFilter = () => {
  const { resultFilterValue, setResultFilterValue } = useRootData((store) => ({
    resultFilterValue: store.mkStatisticsStore.resultFilterValue,
    setResultFilterValue: store.mkStatisticsStore.setResultFilterValue
  }));

  const onResultFilterChange = (e: any) =>
    e.originalEvent.key === 'Enter' && setResultFilterValue(e.value);

  return (
    <InputMask
      autoClear
      className={css.customInputMask}
      mask="9:9"
      onChange={onResultFilterChange}
      placeholder="_:_"
      value={resultFilterValue.result}
    />
  );
};

export default ResultFilter;
