/* eslint-disable jsx-a11y/anchor-is-valid */
import { Route, Switch } from 'react-router-dom';
import React from 'react';
import cn from 'classnames';

import { EntityType } from '../../types';
import { availableEntities } from '../../config';
import { useRootData } from '../../../core/hooks/use-root-data';
import Navbar from '../navbar';
import css from './index.module.css';

const Main: React.FC = () => {
  const { isCollapsedSider, isMobile } = useRootData((store) => ({
    isCollapsedSider: store.mainStore.isCollapsedSider,
    isMobile: store.mainStore.isMobile
  }));

  return (
    <main className={cn(css.main, isCollapsedSider && css.shrinkedMain)}>
      {!isMobile && <Navbar />}
      <div className={cn(css.mainLayout, isMobile && css.mobileMainLayout)}>
        <Switch>
          {Object.values(availableEntities).map(
            ({ exact, path, component }: EntityType) => {
              return (
                <Route
                  key={path}
                  component={component}
                  exact={exact}
                  path={path}
                />
              );
            }
          )}
        </Switch>
      </div>
    </main>
  );
};

export default Main;
