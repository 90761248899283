/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { InputText } from 'primereact/inputtext';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

type CommonTotalFilterProps = {
  field: string;
  header: string;
};
const CommonTotalFilter = ({ field, header }: CommonTotalFilterProps) => {
  const { setCommonTotalFilterValue } = useRootData((store) => ({
    setCommonTotalFilterValue: store.mkStatisticsStore.setCommonTotalFilterValue
  }));

  const onCommonTotalFilterChange = (e: any) =>
    e.key === 'Enter' &&
    setCommonTotalFilterValue(field, e.target.value.trim() || '0');

  return (
    <InputText
      className={css.customInput}
      onKeyDown={onCommonTotalFilterChange}
      placeholder={header}
    />
  );
};

export default CommonTotalFilter;
