/* eslint-disable no-console */
import React from 'react';
import cn from 'classnames';

import css from './index.module.css';

interface LoaderProps {
  isLoading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ isLoading }: LoaderProps) => {
  return (
    <i
      className={cn(
        'pi pi-circle-off',
        cn(css.loadingVisible, { [css.loadingHidden]: !isLoading })
      )}
    />
  );
};

export default Loader;
