/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { MultiSelect } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';

import { DisablabledColumn } from '../types';
import { useRootData } from '../../../../../../core/hooks/use-root-data';

import css from './index.module.css';

const colunnsSets = [
  {
    label: 'Исход',
    columnsKeys: [
      { field: 'champId', label: 'Лига' },
      { field: '_duration', label: 'Продолжительность' },
      { field: '_timeMin', label: 'Время мин.' },
      { field: '_timeAvg', label: 'Время сред.' },
      { field: '_timeMax', label: 'Время макс.' },
      { field: '_coefTotalF', label: 'Кефы тотал F' },
      { field: '_coefTotalB', label: 'Кефы тотал B' },
      { field: '_commonTotal', label: 'Общий тотал' }
    ]
  },
  {
    label: 'Время',
    columnsKeys: [
      { field: 'champId', label: 'Лига' },
      { field: '_rounds', label: 'Раунды' },
      { field: '_coefRound', label: 'Кефы раунд' },
      { field: '_coefRfb', label: 'Кефы добив.' },
      { field: '_coefTotalF', label: 'Кефы тотал F' },
      { field: '_coefTotalB', label: 'Кефы тотал B' },
      { field: '_commonTotal', label: 'Общий тотал' },
      { field: '_coef1x2', label: 'Кефы 1x2' },
      { field: '_flawlessVictory', label: 'Чистая победа' }
    ]
  },
  {
    label: 'Тотал',
    columnsKeys: [
      { field: 'champId', label: 'Лига' },
      { field: '_rounds', label: 'Раунды' },
      { field: '_coefRound', label: 'Кефы раунд' },
      { field: '_coefRfb', label: 'Кефы добив.' },
      { field: '_duration', label: 'Продолжительность' },
      { field: '_timeMin', label: 'Время мин.' },
      { field: '_timeAvg', label: 'Время сред.' },
      { field: '_timeMax', label: 'Время макс.' },
      { field: '_coef1x2', label: 'Кефы 1x2' },
      { field: '_flawlessVictory', label: 'Чистая победа' }
    ]
  }
];

const disablableColumns = [
  { field: 'dateStart', label: 'Дата и время' },
  { field: 'champId', label: 'Лига' },
  { field: 'clid_opps', label: 'Пара' },
  { field: '_rounds', label: 'Раунды' },
  { field: '_result', label: 'Результат' },
  { field: '_coefRound', label: 'Кефы раунд' },
  { field: '_coefRfb', label: 'Кефы добив.' },
  { field: '_duration', label: 'Продолжительность' },
  { field: '_timeMin', label: 'Время мин.' },
  { field: '_timeAvg', label: 'Время сред.' },
  { field: '_timeMax', label: 'Время макс.' },
  { field: '_coefTotalF', label: 'Кефы тотал F' },
  { field: '_coefTotalB', label: 'Кефы тотал B' },
  { field: '_commonTotal', label: 'Общий тотал' },
  { field: '_coef1x2', label: 'Кефы 1x2' },
  { field: '_flawlessVictory', label: 'Чистая победа' }
];

const ColumnsSelector = () => {
  const {
    statsColumnsConfig,
    currentDisabledColumns,
    setStatsColumnsConfig,
    setCurrentDisabledColumns
  } = useRootData((store) => ({
    statsColumnsConfig: store.mkStatisticsStore.statsColumnsConfig,
    currentDisabledColumns: store.mkStatisticsStore.currentDisabledColumns,
    setStatsColumnsConfig: store.mkStatisticsStore.setStatsColumnsConfig,
    setCurrentDisabledColumns: store.mkStatisticsStore.setCurrentDisabledColumns
  }));

  const visibilityChanger = (disabledColumns: DisablabledColumn[]) => {
    const filteredSelectedColumns = statsColumnsConfig.map((colGroup) => {
      // Отключение колонок в группе (например "Лига")
      if (
        disabledColumns.some(({ field }) =>
          colGroup.columns.some((col) => field === col.field)
        )
      )
        return {
          ...colGroup,
          columns: colGroup.columns.map((col) =>
            disabledColumns.some(({ field }) => field === col.field)
              ? { ...col, visibility: false }
              : { ...col, visibility: true }
          )
        };

      // Отключение группы колонок (например "Пара")
      if (disabledColumns.some(({ field }) => field === colGroup.groupField))
        return { ...colGroup, groupVisibility: false };

      // Сброс видимости, т.е. все колонки, которых нет в disabledColumns видимы
      return {
        ...colGroup,
        groupVisibility: true,
        columns: colGroup.columns.map((col) => ({ ...col, visibility: true }))
      };
    });
    setStatsColumnsConfig(filteredSelectedColumns);
  };

  const updateColumns = (disabledColumns: DisablabledColumn[]) => {
    visibilityChanger(disabledColumns);
    setCurrentDisabledColumns(disabledColumns);
  };

  const onColumnToggle = ({ value }: any) => updateColumns(value);

  const onPresetChange = ({ value }: any) =>
    value ? updateColumns(value) : updateColumns([]);

  return (
    <div className={css.columnsSelectionLayout}>
      <SelectButton
        className={css.columnsSelectButton}
        onChange={onPresetChange}
        optionValue="columnsKeys"
        options={colunnsSets}
        value={currentDisabledColumns}
      />
      <MultiSelect
        className={css.columnsMultiSelect}
        name="columnsSelector"
        onChange={onColumnToggle}
        optionLabel="label"
        options={disablableColumns}
        placeholder="Скрытые колонки"
        selectedItemsLabel="{0} столбцов скрыто..."
        value={currentDisabledColumns}
      />
    </div>
  );
};

export default ColumnsSelector;
