/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';

import css from './index.module.css';

const getRfbColumnsConfig = () => [
  {
    groupHeader: `R`,
    groupClassName: 'dobiv_r',
    columns: [
      { key: `r`, header: `Общ.` },
      { key: `rp1`, header: `П1` },
      { key: `rp2`, header: `П2` }
    ].map(({ key, header }) => {
      const field = `${key}`;
      return {
        field,
        header,
        width: '8rem',
        renderer: (rowData: any) => {
          const value = rowData[field];

          const rClass = cn(css.dataTableTag, {
            [css.rHighLight]: +value >= 1 && +value <= 1.8
          });

          return <div className={rClass}>{value}</div>;
        }
      };
    })
  },
  {
    groupHeader: `F`,
    groupClassName: 'dobiv_f',
    columns: [
      { key: `f`, header: `Общ.` },
      { key: `fp1`, header: `П1` },
      { key: `fp2`, header: `П2` }
    ].map(({ key, header }) => {
      const field = `${key}`;
      return {
        field,
        header,
        width: '8rem',
        renderer: (rowData: any) => {
          const value = rowData[field];

          const fClass = cn(css.dataTableTag, {
            [css.fHighLight]: +value >= 1 && +value <= 3.3
          });

          return <div className={fClass}>{value}</div>;
        }
      };
    })
  },
  {
    groupHeader: `B`,
    groupClassName: 'dobiv_b',
    columns: [
      { key: `b`, header: `Общ.` },
      { key: `bp1`, header: `П1` },
      { key: `bp2`, header: `П2` }
    ].map(({ key, header }) => {
      const field = `${key}`;
      return {
        field,
        header,
        width: '8rem',
        renderer: (rowData: any) => {
          const value = rowData[field];

          const bClass = cn(css.dataTableTag, {
            [css.bHighLight]: +value >= 1 && +value <= 5
          });

          return <div className={bClass}>{value}</div>;
        }
      };
    })
  }
];

export default getRfbColumnsConfig;
