import React from 'react';

import Accaunt from './accaunt';
import NavigationSwitcher from './nav-subroutes';
import Trigger from './trigger-item';
import css from './index.module.css';

const Navbar = () => {
  return (
    <div className={css.navbar}>
      <Trigger />
      <NavigationSwitcher />
      <Accaunt />
    </div>
  );
};

export default Navbar;
