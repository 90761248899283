/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import { useMediaQuery } from 'react-responsive';
import { useRootData } from '../../core/hooks/use-root-data';

import { Main, Sidebar, SidebarMobile } from '../components';

import css from './index.module.css';

const Page = () => {
  const { setIsPortrait, setIsMobile } = useRootData((store) => ({
    setIsPortrait: store.mainStore.setIsPortrait,
    setIsMobile: store.mainStore.setIsMobile
  }));

  // TODO: настроить queries для медиек
  const isMobile = useMediaQuery({ maxWidth: 600 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });

  useEffect(() => setIsPortrait(isPortrait), [isPortrait, setIsPortrait]);

  useEffect(() => setIsMobile(isMobile), [isMobile, setIsMobile]);

  const Desktop = ({ children }: any) => (!isMobile ? children : null);
  const Mobile = ({ children }: any) => (isMobile ? children : null);

  return (
    <div className={css.primereactOverride}>
      <Desktop>
        <Sidebar />
        <Main />
      </Desktop>
      <Mobile>
        <SidebarMobile />
        <Main />
      </Mobile>
    </div>
  );
};

export { Page };
