/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';

import css from './index.module.css';

import { useRootData } from '../../../../../../../../core/hooks/use-root-data';

const CommonTotalRenderer = ({ rowData, field }: any) => {
  const { filter } = useRootData((store) => ({
    filter: store.mkStatisticsStore.commonTotalGroupFilterValue
  }));

  const commonTotalValue = rowData[field].v;

  const isPassed = () => {
    if (filter !== 'passed' || commonTotalValue === 0) return false;

    const [resultP1, resultP2] = rowData.result.v.split(':');

    if (typeof resultP1 === 'undefined' || typeof resultP2 === 'undefined')
      return false;

    const total = Number(resultP1) + Number(resultP2);

    const val =
      Number(field.replace('t', '').replace('m', '').replace('b', '')) / 10;
    if (field.includes('m') && total < val) return true;
    if (field.includes('b') && total > val) return true;

    return false;
  };

  const commonTotalClass = cn(css.dataTableTag, {
    [css.commonTotal_passed]: isPassed()
  });

  return <div className={commonTotalClass}>{commonTotalValue}</div>;
};

export default CommonTotalRenderer;
