/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';

import { XYChart } from '@amcharts/amcharts4/charts';
import { addLicense } from '@amcharts/amcharts4/core';

import { useRootData } from '../../../../../../../../core/hooks/use-root-data';

import { createChart, createSeriesInstance, createValueAxis } from './utils';
import css from './index.module.css';

addLicense('CH523423423');

const AnalisisChart: React.FC = () => {
  const { isLoading, dataForChart } = useRootData((store) => ({
    isLoading: store.mkAnalisisStore.isLoading,
    dataForChart: store.mkAnalisisStore.dataForChart
  }));

  const [chartInstance, setChartInstances] = useState<XYChart | null>(null);

  useEffect(() => {
    const { chart } = createChart('chart');
    setChartInstances(chart);

    return () => chart.dispose();
  }, []);

  useEffect(() => {
    if (chartInstance) {
      if (dataForChart.totalData) {
        chartInstance.yAxes.clear();
        chartInstance.series.clear();

        console.log('dataForChart.totalData----', dataForChart.totalData);
        chartInstance.data = dataForChart.totalData;

        const valueAxis = createValueAxis(chartInstance);

        createSeriesInstance(chartInstance, valueAxis, 'avg');
        createSeriesInstance(chartInstance, valueAxis, 'movingAvg');
      }
    }
  }, [chartInstance, dataForChart]);

  return (
    <div className={css.chartLayout}>
      <div className={css.chart} id="chart" />
    </div>
  );
};

export default AnalisisChart;
