/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import cn from 'classnames';

import { Dropdown } from 'primereact/dropdown';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from './index.module.css';

const PairSelection: React.FC = () => {
  const {
    heroesOptions,
    leftHero,
    rightHero,
    setLeftHero,
    setRightHero,
    switchHeroes
  } = useRootData((store) => ({
    heroesOptions: store.mkMainStore.heroesOptions,
    leftHero: store.mkAnalisisStore.leftHero,
    rightHero: store.mkAnalisisStore.rightHero,
    setLeftHero: store.mkAnalisisStore.setLeftHero,
    setRightHero: store.mkAnalisisStore.setRightHero,
    switchHeroes: store.mkAnalisisStore.switchHeroes
  }));

  const handleChangeLeftHero = (e: any) => {
    setLeftHero(e.value);
  };
  const handleChangeRightHero = (e: any) => {
    setRightHero(e.value);
  };

  const handleSwitchHeroes = () => switchHeroes();

  return (
    <div className={css.pairSelectionLayout}>
      <Dropdown
        showClear
        className={css.pairSelectionDropdown}
        onChange={handleChangeLeftHero}
        options={heroesOptions}
        placeholder="Левый герой"
        value={leftHero}
      />
      <i
        className={cn('pi pi-sort-alt', css.switchHeroesButton)}
        onClick={handleSwitchHeroes}
        role="button"
        tabIndex={0}
      />
      <Dropdown
        showClear
        className={css.pairSelectionDropdown}
        onChange={handleChangeRightHero}
        options={heroesOptions}
        placeholder="Правый герой"
        value={rightHero}
      />
    </div>
  );
};

export default memo(PairSelection);
