/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

const coefRoundOptions = Array.from(
  { length: 9 },
  (_, key) => `${key + 1}`
).map((round) => ({
  value: round,
  label: `Раунд ${round}`
}));

const CoefRoundGroupFilter = () => {
  const { filter, setFilter } = useRootData((store) => ({
    filter: store.mkStatisticsStore.coefRoundGroupFilterValue,
    setFilter: store.mkStatisticsStore.setCoefRoundGroupFilterValue
  }));

  const onCoefRoundFilterChange = ({ value }: any) => setFilter(value);

  return (
    <Dropdown
      showClear
      appendTo={document.body}
      className={css.customDropdown}
      onChange={onCoefRoundFilterChange}
      options={coefRoundOptions}
      placeholder="Кефы раунд"
      value={filter}
    />
  );
};

export default CoefRoundGroupFilter;
