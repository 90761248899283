/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';
import AnalisisMK from './analisis';
import StatisticsMK from './statistics';

import { useRootData } from '../../../../../core/hooks/use-root-data';
import css from './index.module.css';

const MortalKombat: React.FC = () => {
  const { fetchMkOpp } = useRootData((store) => ({
    fetchMkOpp: store.mkMainStore.fetchMkOpp
  }));

  useEffect(() => {
    console.log('fetchMkOpp');

    fetchMkOpp();
  }, [fetchMkOpp]);

  return (
    <div className={css.mortalKombatLayout}>
      <Switch>
        <Route exact component={AnalisisMK} path="/mk" />
        {/* <Route exact component={StatisticsMK} path="/mk" /> */}
        <Route component={StatisticsMK} path="/mk/statistics" />
        <Route component={AnalisisMK} path="/mk/analisis" />
      </Switch>
    </div>
  );
};

export default MortalKombat;
