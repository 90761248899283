/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties } from 'react';

import { useRootData } from '../../../../../../../../core/hooks/use-root-data';
import css from './index.module.css';

const roundAliases: { [key: number]: string } = {
  0: '',
  10: 'П1',
  11: 'П1 R',
  12: 'П1 F',
  13: 'П1 B',
  20: 'П2',
  21: 'П2 R',
  22: 'П2 F',
  23: 'П2 B',
  1: 'R',
  2: 'F',
  3: 'B'
};

type RoundsRendererProps = {
  rowData: any;
  field: string;
};

const RoundsRenderer = ({ rowData, field }: RoundsRendererProps) => {
  const { filter } = useRootData((store) => ({
    filter: store.mkStatisticsStore.roundsGroupFilterValue
  }));

  const { dobiv, v, winner, wt } = rowData[field];
  const { v: sp1Value } = rowData.sp1;
  const { v: sp2Value } = rowData.sp2;

  const style = {} as CSSProperties;
  // FIXME: вынести в классы// мб не получится
  if (filter === 'F_B_W') {
    if (wt) {
      style.backgroundColor = 'rgb(255, 200, 0)';
      if (dobiv === 'F')
        style.background = 'linear-gradient(to left,rgb(255, 200, 0),#FBA7A2)';
      if (dobiv === 'B')
        style.background = 'linear-gradient(to left,rgb(255, 200, 0),#8DD98D)';
    } else {
      if (dobiv === 'F') style.backgroundColor = '#FBA7A2';
      if (dobiv === 'B') style.backgroundColor = '#8DD98D';
    }
  }
  if (filter === 'F_B') {
    if (dobiv === 'F') style.backgroundColor = '#FBA7A2';
    if (dobiv === 'B') style.backgroundColor = '#8DD98D';
  }
  if (filter === 'F') {
    if (dobiv === 'F') style.backgroundColor = '#FBA7A2';
  }
  if (filter === 'B') {
    if (dobiv === 'B') style.backgroundColor = '#8DD98D';
  }
  if (filter === 'W') {
    if (wt) style.backgroundColor = 'rgb(255, 200, 0)';
  }
  if (filter === 'P1P2') {
    if (winner === 1) {
      style.backgroundColor = '#000000';
      style.color = '#FFFFFF';
    }
    if (winner === 2) style.backgroundColor = '#FD7F01';
  }
  if (filter === 'P1') {
    if (winner === 1) {
      style.backgroundColor = '#000000';
      style.color = '#FFFFFF';
    }
  }
  if (filter === 'P2') {
    if (winner === 2) style.backgroundColor = '#FD7F01';
  }
  if (filter === 'out') {
    if (sp1Value > sp2Value && winner === 1) {
      style.backgroundColor = '#000000';
      style.color = '#FFFFFF';
    }
    if (sp1Value < sp2Value && winner === 2) {
      style.backgroundColor = '#000000';
      style.color = '#FFFFFF';
    }
  }
  return (
    <div className={css.dataTableTag} style={style}>
      {roundAliases[v]}
    </div>
  );
};

export default RoundsRenderer;
