/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';

import css from './index.module.css';

const ResultRenderer = ({ rowData }: any) => {
  const result = rowData.result?.v;

  const resultClass = cn(css.dataTableTag, {
    [css.result_left_5]: result.indexOf('5:') !== -1,
    [css.result_right_5]: result.indexOf(':5') !== -1,
    [css.result_5_0]: result === '5:0' || result === '0:5',
    [css.result_5_5]: result.indexOf(':5') === -1 && result.indexOf('5:') === -1
  });

  return <div className={resultClass}>{result}</div>;
};

export default ResultRenderer;
