/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

const pairOptions = [
  { value: 'F_B_W', label: 'Фаталити+Бруталити+Чистая победа' },
  { value: 'F_B', label: 'Фаталити+Бруталити' },
  { value: 'F', label: 'Фаталити' },
  { value: 'B', label: 'Бруталити' },
  { value: 'W', label: 'Чистая победа' },
  { value: 'P1P2', label: 'П1+П2' },
  { value: 'P1', label: 'П1' },
  { value: 'P2', label: 'П2' },
  { value: 'out', label: 'Победил аутсайдер' }
];

const RoundsGroupFilter = () => {
  const { filter, setFilter } = useRootData((store) => ({
    filter: store.mkStatisticsStore.roundsGroupFilterValue,
    setFilter: store.mkStatisticsStore.setRoundsGroupFilterValue
  }));

  const onRoundsGroupFilterChange = ({ value }: any) => setFilter(value);

  return (
    <Dropdown
      showClear
      appendTo={document.body}
      className={css.customDropdown}
      onChange={onRoundsGroupFilterChange}
      options={pairOptions}
      placeholder="Раунды"
      value={filter}
    />
  );
};

export default RoundsGroupFilter;
