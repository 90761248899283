/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { MkHero, MkHeroes, MkLeagues } from '../statistics/types';
import { getMkOpp } from '../api';

const shortLeagues: any = {
  'Mortal Kombat X': 'MK X',
  'Mortal Kombat 11 (BO3)': 'MK 11 (BO3)',
  'Mortal Kombat 11 (BO9)': 'MK 11 (BO9)',
  'Mortal Kombat 11': 'MK 11'
};

export const createMkMainStore = () => ({
  heroesOptions: [] as any,

  oppHeroesDictionary: {} as MkHeroes,
  oppLeagueDictionary: {} as MkLeagues,
  setDictionaries({ heroes, leagues }: any) {
    this.oppLeagueDictionary = leagues;
    this.oppHeroesDictionary = heroes;

    this.heroesOptions = Object.entries(heroes).map(([key, value]) => ({
      value: key,
      label: value
    }));
  },

  async fetchMkOpp() {
    try {
      const {
        data: { items }
      } = await getMkOpp();

      const heroes = {} as MkHeroes;
      const leagues = Object.keys(items).reduce((acc, id) => {
        const { name, opp } = items[id];

        opp.forEach(({ clid_opp: heroId, opp: heroName }: MkHero) => {
          heroes[heroId] = heroName;
        });

        return { ...acc, [id]: shortLeagues[name] };
      }, {});

      this.setDictionaries({ heroes, leagues });
    } catch (error) {
      console.log(error);
    }
  }
});

export type MkMainStoreType = ReturnType<typeof createMkMainStore>;
