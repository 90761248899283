/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

const durationOptions = [
  { value: 'min_avg_max', label: 'Мин + Сред + Макс' },
  { value: 'min', label: 'Меньшее' },
  { value: 'avg', label: 'Среднее' },
  { value: 'max', label: 'Максимальное' }
];

const DurationGroupFilter = () => {
  const { filter, setFilter } = useRootData((store) => ({
    filter: store.mkStatisticsStore.durationGroupFilterValue,
    setFilter: store.mkStatisticsStore.setDurationGroupFilterValue
  }));

  const onDurationFilterChange = ({ value }: any) => setFilter(value);

  return (
    <Dropdown
      showClear
      appendTo={document.body}
      className={css.customDropdown}
      onChange={onDurationFilterChange}
      options={durationOptions}
      placeholder="Продолжительность"
      value={filter}
    />
  );
};

export default DurationGroupFilter;
