/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { memo, useMemo } from 'react';

import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';
import getRfbColumnsConfig from './columns';

// import css from './index.module.css';

const RfbTable = () => {
  const { dataForRfbTable } = useRootData((store) => ({
    dataForRfbTable: store.mkAnalisisStore.dataForRfbTable
  }));

  const rfbColumnsConfig = useMemo(() => getRfbColumnsConfig(), []);
  // TODO: фомрмировать сущности за один проход по конфигу
  const { headerColumnGroup, bodyColumnsElements } = useMemo(() => {
    const columnsGroups = rfbColumnsConfig.map(({ groupHeader, columns }) => {
      return (
        <Column
          key={groupHeader}
          colSpan={columns.length}
          header={groupHeader}
        />
      );
    });

    const columnsElements = rfbColumnsConfig.map(({ columns }) => {
      return columns.map(({ field, header, renderer, width }) => {
        return (
          <Column
            key={field}
            body={renderer}
            colSpan={1}
            field={field}
            header={header}
          />
        );
      });
    });

    return {
      headerColumnGroup: (
        <ColumnGroup>
          <Row>{columnsGroups}</Row>
          <Row>{columnsElements}</Row>
        </ColumnGroup>
      ),
      bodyColumnsElements: columnsElements
    };
  }, [rfbColumnsConfig]);

  return (
    <DataTable headerColumnGroup={headerColumnGroup} value={dataForRfbTable}>
      {bodyColumnsElements}
    </DataTable>
  );
};

export default memo(RfbTable);
