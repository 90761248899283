/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { InputText } from 'primereact/inputtext';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

type CoefRfbFilterProps = {
  field: string;
};
const CoefRfbFilter = ({ field }: CoefRfbFilterProps) => {
  const { setRfbFilterValue } = useRootData((store) => ({
    setRfbFilterValue: store.mkStatisticsStore.setRfbFilterValue
  }));

  const onCoefRfbFilterChange = (e: any) =>
    e.key === 'Enter' &&
    setRfbFilterValue(`s${field}r`, e.target.value.trim() || '0');

  return (
    <InputText
      className={css.customInput}
      onKeyDown={onCoefRfbFilterChange}
      placeholder={field.toUpperCase()}
    />
  );
};

export default CoefRfbFilter;
