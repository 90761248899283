/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo } from 'react';

import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';

import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from './index.module.css';

const roundsCountOptions = [
  { value: 0, label: 'Все бои' },
  { value: 1, label: '1 бой' },
  { value: 3, label: '3 боя' },
  { value: 5, label: '5 боев' },
  { value: 10, label: '10 боев' },
  { value: 15, label: '15 боев' },
  { value: 25, label: '25 боев' },
  { value: 50, label: '50 боев' },
  { value: 100, label: '100 боев' }
];

const ru = {
  firstDayOfWeek: 1,
  dayNames: [
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
    'воскресенье'
  ],
  dayNamesShort: ['пон', 'втор', 'сред', 'четв', 'пятн', 'субб', 'воскр'],
  dayNamesMin: ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'],
  monthNames: [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь'
  ],
  monthNamesShort: [
    'янв',
    'фев',
    'мар',
    'апр',
    'май',
    'июн',
    'июл',
    'авг',
    'сен',
    'окт',
    'ноя',
    'дек'
  ],
  today: 'Сегодня',
  clear: 'Сброс'
};

const SelectionParameters: React.FC = () => {
  const { roundsCount, startDate, setRoundsCount, setStartDate } = useRootData(
    (store) => ({
      roundsCount: store.mkAnalisisStore.roundsCount,
      startDate: store.mkAnalisisStore.startDate,
      setRoundsCount: store.mkAnalisisStore.setRoundsCount,
      setStartDate: store.mkAnalisisStore.setStartDate
    })
  );

  const handleChangeRoundsCount = (e: any) => {
    setRoundsCount(e.value);
  };

  const handleChangeStartDate = (e: any) => {
    setStartDate(e.value);
  };

  return (
    <div className={css.selectionParametersLayout}>
      <div className={css.parametersLayout}>
        <h4>Учитывать бои с:</h4>
        <Calendar
          showButtonBar
          locale={ru}
          onChange={handleChangeStartDate}
          value={startDate}
        />
      </div>
      <div className={css.parametersLayout}>
        <h4>Количество боев:</h4>
        <Dropdown
          className={css.selectionParametersDropdown}
          onChange={handleChangeRoundsCount}
          options={roundsCountOptions}
          placeholder="Количество боев"
          value={roundsCount}
        />
      </div>
    </div>
  );
};

export default memo(SelectionParameters);
