/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';

import css from './index.module.css';

import { useRootData } from '../../../../../../../../core/hooks/use-root-data';

const CoefRoundRenderer = ({ rowData, field }: any) => {
  const { filter } = useRootData((store) => ({
    filter: store.mkStatisticsStore.coefRoundGroupFilterValue
  }));

  const coefValue = rowData[field].v;

  const coefRoundClass = cn(css.dataTableTag, {
    [css.coefGroup_SP_winner]:
      (field === 'sp1' && filter && rowData[`r${filter}`].winner === 1) ||
      (field === 'sp2' && filter && rowData[`r${filter}`].winner === 2)
  });

  return <div className={coefRoundClass}>{coefValue}</div>;
};

export default CoefRoundRenderer;
