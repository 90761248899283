/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';
import round from 'lodash.round';

import css from './index.module.css';

const getMainColumnsConfig = () => [
  {
    groupHeader: `🎲`,
    groupField: '_time',
    groupClassName: 'time',
    columns: [{ header: `Раунд`, field: `round` }].map(({ field, header }) => {
      return {
        field,
        header,
        width: '3rem',
        renderer: (row: any) => (
          <div className={cn({ [css.summaryRow]: row.round === 'Сумма' })}>
            {row[field]}
          </div>
        )
      };
    })
  },
  {
    groupHeader: 'Игроки',
    groupClassName: 'players',
    columns: [
      { key: 'pTotal', header: 'Всего' },
      { key: 'p1', header: 'П1' },
      { key: 'p1p', header: 'П1 %' },
      { key: 'p2', header: 'П2' },
      { key: 'p2p', header: 'П2 %' }
    ].map(({ key, header }) => {
      const field = `${key}`;
      return {
        field,
        header,
        width: '8rem',
        renderer: (row: any) => {
          const isSummaryRow = row.round === 'Сумма';
          const value = row[field];

          if (field === 'p2p' || field === 'p1p') {
            const pClass = cn(css.tableTag, {
              [css.summaryRow]: isSummaryRow,
              [css.pMoreThen50]: value > 50 && value <= 65,
              [css.pMoreThen65]: value > 65
            });
            return <div className={pClass}>{value}</div>;
          }

          return (
            <div className={cn({ [css.summaryRow]: isSummaryRow })}>
              {row[field]}
            </div>
          );
        }
      };
    })
  },
  {
    groupHeader: 'Без добивания (R)',
    groupClassName: 'dobiv_r',
    columns: [
      { key: 'rTotal', header: 'Всего' },
      { key: 'rCommon', header: 'Общий %' },
      { key: 'rP1', header: 'R П1' },
      { key: 'rP1P', header: 'R П1 %' },
      { key: 'rP2', header: 'R П2' },
      { key: 'rP2P', header: 'R П2 %' }
    ].map(({ key, header }) => {
      const field = `${key}`;
      return {
        field,
        header,
        width: '8rem',
        renderer: (row: any) => {
          const isSummaryRow = row.round === 'Сумма';

          if (field === 'rCommon') {
            const commonValue = round((row.rTotal * 100) / row.pTotal, 1);
            const rClass = cn(css.tableTag, {
              [css.summaryRow]: isSummaryRow,
              [css.rMoreThen50]: commonValue > 50 && commonValue <= 65,
              [css.rMoreThen65]: commonValue > 65
            });
            return <div className={rClass}>{commonValue}</div>;
          }

          return (
            <div className={cn({ [css.summaryRow]: isSummaryRow })}>
              {row[field]}
            </div>
          );
        }
      };
    })
  },
  {
    groupHeader: 'Фаталити (F)',
    groupClassName: 'dobiv_f',
    columns: [
      { key: 'fTotal', header: 'Всего' },
      { key: 'fTolalP', header: 'Общий %' },
      { key: 'fP1', header: 'F П1' },
      { key: 'fP1P', header: 'F П1 %' },
      { key: 'fP2', header: 'F П2' },
      { key: 'fP2P', header: 'F П2 %' }
    ].map(({ key, header }) => {
      const field = `${key}`;
      return {
        field,
        header,
        width: '8rem',
        renderer: (row: any) => {
          const isSummaryRow = row.round === 'Сумма';

          if (field === 'fCommon') {
            const commonValue = row[key];
            const rClass = cn(css.tableTag, {
              [css.summaryRow]: isSummaryRow,
              [css.fMoreThen40]: commonValue > 40 && commonValue < 50,
              [css.fMoreThen50]: commonValue > 50
            });
            return <div className={rClass}>{commonValue}</div>;
          }

          return (
            <div className={cn({ [css.summaryRow]: isSummaryRow })}>
              {row[field]}
            </div>
          );
        }
      };
    })
  },
  {
    groupHeader: 'Бруталити (B)',
    groupClassName: 'dobiv_b',
    columns: [
      { key: 'bTotal', header: 'Всего' },
      { key: 'bCommon', header: 'Общий %' },
      { key: 'bP1', header: 'B П1' },
      { key: 'bP1P', header: 'B П1 %' },
      { key: 'bP2', header: 'B П2' },
      { key: 'bP2P', header: 'B П2 %' }
    ].map(({ key, header }) => {
      const field = `${key}`;
      return {
        field,
        header,
        width: '8rem',
        renderer: (row: any) => {
          const isSummaryRow = row.round === 'Сумма';

          if (field === 'bCommon') {
            const commonValue =
              row.pTotal > 0 ? round((row.bTotal * 100) / row.pTotal, 1) : 0;
            const bClass = cn(css.tableTag, {
              [css.summaryRow]: isSummaryRow,
              [css.bMoreThen30]: commonValue > 30 && commonValue < 40,
              [css.bMoreThen40]: commonValue > 40
            });
            return <div className={bClass}>{commonValue}</div>;
          }

          return (
            <div className={cn({ [css.summaryRow]: isSummaryRow })}>
              {row[field]}
            </div>
          );
        }
      };
    })
  },
  {
    groupHeader: `🕕`,
    groupField: '_time',
    groupClassName: 'time',
    columns: [{ header: `Время`, key: `timeA` }].map(({ key, header }) => {
      const field = `${key}`;
      return {
        field,
        header,
        width: '3rem',
        renderer: (row: any) => (
          <div className={cn({ [css.summaryRow]: row.round === 'Сумма' })}>
            {row[field]}
          </div>
        )
      };
    })
  }
];

export default getMainColumnsConfig;
