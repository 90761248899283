/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cn from 'classnames';

import Logo from '../logo-item';
import NavigationItems from '../nav-items';
import Theme from '../theme-item';

import { useRootData } from '../../../../core/hooks/use-root-data';
import css from '../index.module.css';

const Sidebar = () => {
  const { isCollapsedSider } = useRootData((store) => ({
    isCollapsedSider: store.mainStore.isCollapsedSider
  }));

  return (
    <nav className={cn(css.sidebar, isCollapsedSider && css.openedSidebar)}>
      <ul className={css.nav}>
        <>
          <Logo />

          <NavigationItems />

          <Theme />
        </>
      </ul>
    </nav>
  );
};

export default Sidebar;
