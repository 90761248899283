/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';

import { AverageTable, ColumnsSelector } from '../../../../common';
import { useRootData } from '../../../../../../../../core/hooks/use-root-data';
import AutoUpdateSwitch from '../autoUpdate';

import css from './index.module.css';

const avgColumns = [
  {
    groupHeader: 'R',
    groupField: '_dobiv_r',
    groupClassName: 'dobiv_r',
    columns: [
      { header: `Общ.`, field: `r` },
      { header: `П1`, field: `rp1` },
      { header: `П2`, field: `rp2` }
    ].map(({ field, header }) => {
      return {
        field,
        header,
        width: '2rem',
        renderer: (rowData: any) => {
          const rValue = rowData[field];
          return (
            <div
              className={cn(css.avgTag, {
                [css.grayRangeCell]: rValue >= 1 && rValue <= 1.8
              })}
            >
              {rValue}
            </div>
          );
        }
      };
    })
  },
  {
    groupHeader: 'F',
    groupField: '_dobiv_f',
    groupClassName: 'dobiv_f',
    columns: [
      { header: `Общ.`, field: `f` },
      { header: `П1`, field: `fp1` },
      { header: `П2`, field: `fp2` }
    ].map(({ field, header }) => {
      return {
        field,
        header,
        width: '2rem',
        renderer: (rowData: any) => {
          const rValue = rowData[field];
          return (
            <div
              className={cn(css.avgTag, {
                [css.pinkRangeCell]: rValue >= 1 && rValue <= 3.3
              })}
            >
              {rValue}
            </div>
          );
        }
      };
    })
  },
  {
    groupHeader: `B`,
    groupField: '_dobiv_b',
    groupClassName: 'dobiv_b',
    columns: [
      { header: `Общ.`, field: `b` },
      { header: `П1`, field: `bp1` },
      { header: `П2`, field: `bp2` }
    ].map(({ field, header }) => {
      return {
        field,
        header,
        width: '2rem',
        renderer: (rowData: any) => {
          const rValue = rowData[field];
          return (
            <div
              className={cn(css.avgTag, {
                [css.greenRangeCell]: rValue >= 1 && rValue <= 5
              })}
            >
              {rValue}
            </div>
          );
        }
      };
    })
  },
  {
    groupHeader: `🕕`,
    groupField: '_time',
    groupClassName: 'time',
    columns: [{ header: `Время`, field: `time` }].map(({ field, header }) => {
      return {
        field,
        header,
        width: '3rem',
        renderer: (rowData: any) => <div>{rowData[field]}</div>
      };
    })
  }
];

const StatsTableSettings = () => {
  const { avgTableData, isMobile } = useRootData((store) => ({
    avgTableData: store.mkStatisticsStore.avgTableData,
    isMobile: store.mainStore.isMobile
  }));

  const Desktop = ({ children }: any) => (!isMobile ? children : null);

  return (
    <>
      <Desktop>
        <div className={css.tableHeader}>
          <div className={css.columnsSelectorLayout}>
            <ColumnsSelector />
          </div>
          <div className={css.autoUpdateLayout}>
            <AutoUpdateSwitch />
          </div>
          <div className={css.avgTableLayout}>
            <AverageTable columnsConfig={avgColumns} data={avgTableData} />
          </div>
        </div>
      </Desktop>
    </>
  );
};

export { StatsTableSettings };
