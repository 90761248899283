/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';

import { availableEntities } from '../../../../config';
import css from './index.module.css';

const Home: React.FC = () => {
  return (
    // Магический див, без него отступ не работает если сразу див с гридом передавать
    <div className={css.homeLayout}>
      <div className={cn(css.content)}>
        {Object.keys(availableEntities).map((item) => {
          const { title, path } = availableEntities[item];
          if (title === 'Home') return null;
          return (
            <Link key={title} to={path}>
              <div className={cn(css.card)}>{title}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Home;
