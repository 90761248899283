import React from 'react';

import './core.module.css';

import { Page } from '../../bk/page';

const Core = () => {
  return <Page />;
};

export default Core;
