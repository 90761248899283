/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import cn from 'classnames';

import { useRootData } from '../../../../core/hooks/use-root-data';
import css from './index.module.css';

const Trigger = () => {
  const { triggerSiderColapse } = useRootData((store) => ({
    triggerSiderColapse: store.mainStore.triggerSiderColapse,
    isCollapsedSider: store.mainStore.isCollapsedSider
  }));

  const handleTrigger = () => triggerSiderColapse();

  return (
    <div
      className={cn(
        css.sidebarOpenTrigger
        // isCollapsedSider && css.sidebarCloseTrigger
      )}
      onClick={handleTrigger}
      role="button"
      tabIndex={0}
    >
      <span />
      <span />
      <span />
    </div>
  );
};

export default Trigger;
