/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cn from 'classnames';

import css from '../index.module.css';

const Logo = () => {
  return (
    <li className={css.logo}>
      <a className={cn(css.navLink, css.logoLink)} href="#">
        <span className={cn(css.linkText, css.logoText)}>BKSTAT</span>
      </a>
    </li>
  );
};

export default Logo;
