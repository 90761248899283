/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { InputText } from 'primereact/inputtext';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

type TimeMaxFilterProps = {
  field: string;
  header: string;
};
const TimeMaxFilter = ({ field, header }: TimeMaxFilterProps) => {
  const { setTimeMaxFilterValue } = useRootData((store) => ({
    setTimeMaxFilterValue: store.mkStatisticsStore.setTimeMaxFilterValue
  }));

  const onTimeMaxFilterChange = (e: any) =>
    e.key === 'Enter' &&
    e.target.value &&
    setTimeMaxFilterValue(field, e.target.value.trim() || '0');

  return (
    <InputText
      className={css.customInput}
      onKeyDown={onTimeMaxFilterChange}
      placeholder={header}
    />
  );
};

export default TimeMaxFilter;
