/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';

import css from './index.module.css';

import { useRootData } from '../../../../../../../../core/hooks/use-root-data';

const PairRenderer = ({ rowData, field }: any) => {
  const { filter, oppHeroesDictionary = {} } = useRootData((store) => ({
    filter: store.mkStatisticsStore.pairGroupFilterValue,
    oppHeroesDictionary: store.mkMainStore.oppHeroesDictionary
  }));

  const heroName = oppHeroesDictionary[rowData[field]?.v];
  const result = rowData.result?.v;
  const isLeftColumn = field === 'clid_opp1';
  const isRightColumn = !isLeftColumn;

  const isWinner =
    filter === 'winner' &&
    ((result.includes('5:') && isLeftColumn) ||
      (result.includes(':5') && isRightColumn));

  const isFatality =
    filter === 'fatality' &&
    [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce((acc, roundIndex) => {
      if (rowData[`r${roundIndex}`].dobiv === 'F') {
        if (isLeftColumn && rowData[`r${roundIndex}`].winner === 1) return true;
        if (isRightColumn && rowData[`r${roundIndex}`].winner === 2)
          return true;
      }
      return acc;
    }, false);

  const isBrutality =
    filter === 'brutality' &&
    [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce((acc, roundIndex) => {
      if (rowData[`r${roundIndex}`].dobiv === 'B') {
        if (isLeftColumn && rowData[`r${roundIndex}`].winner === 1) return true;
        if (isRightColumn && rowData[`r${roundIndex}`].winner === 2)
          return true;
      }
      return acc;
    }, false);

  const pairClass = cn(css.dataTableTag, {
    [css.pair_winner]: isWinner,
    [css.pair_fatality]: isFatality,
    [css.pair_brutality]: isBrutality
  });

  return <div className={pairClass}>{heroName}</div>;
};

export default PairRenderer;
