/* eslint-disable @typescript-eslint/no-explicit-any */
import * as am4charts from '@amcharts/amcharts4/charts';
import * as am4core from '@amcharts/amcharts4/core';

import { ValueAxis, XYChart } from '@amcharts/amcharts4/charts';

const interfaceColors = new am4core.InterfaceColorSet();

const createChart = (div: string) => {
  const chart = am4core.create(div, am4charts.XYChart);

  chart.padding(0, 16, 0, 16);
  chart.leftAxesContainer.layout = 'vertical';
  chart.responsive.enabled = true;

  const dateAxis = createCategoryAxis(chart);

  const scrollbarX = new am4core.Scrollbar();
  chart.scrollbarX = scrollbarX;
  chart.scrollbarX.startGrip.icon.disabled = true;
  chart.scrollbarX.endGrip.icon.disabled = true;

  const cursor = new am4charts.XYCursor();
  chart.cursor = cursor;
  chart.cursor.xAxis = dateAxis;

  return { chart };
};

const createCategoryAxis = (chart: XYChart) => {
  const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  categoryAxis.renderer.grid.template.location = 0;
  categoryAxis.renderer.minGridDistance = 10;
  categoryAxis.startLocation = 0.5;
  categoryAxis.endLocation = 0.5;
  categoryAxis.dataFields.category = 'point';

  categoryAxis.renderer.ticks.template.disabled = false;
  categoryAxis.renderer.ticks.template.strokeOpacity = 0.2;
  categoryAxis.renderer.ticks.template.length = 4;
  categoryAxis.renderer.labels.template.location = 0.5;

  return categoryAxis;
};

const createSeriesInstance = (
  chart: am4charts.XYChart,
  valueAxis: ValueAxis,
  field: string
) => {
  //   const valueAxis = createValueAxis(chart);

  const seriesInstance = chart.series.push(new am4charts.LineSeries());

  seriesInstance.strokeWidth = 2;
  seriesInstance.tensionX = 0.8;

  seriesInstance.dataFields.categoryX = 'point';
  seriesInstance.dataFields.valueY = field;
  seriesInstance.yAxis = valueAxis;
  seriesInstance.name = 'Время';
  seriesInstance.tooltipText = `Значение: {valueY}`;

  return seriesInstance;
};

const createValueAxis = (chart: am4charts.XYChart) => {
  const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

  valueAxis.renderer.gridContainer.background.fill = interfaceColors.getFor(
    'alternativeBackground'
  );
  valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;

  valueAxis.renderer.inside = true;
  valueAxis.renderer.minLabelPosition = 0.01;
  valueAxis.renderer.maxLabelPosition = 0.99;

  valueAxis.renderer.labels.template.padding(0, 2, 0, 2);

  return valueAxis;
};

export { createChart, createValueAxis, createSeriesInstance };
