/* eslint-disable no-console */
import React, { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';

import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';
import { useRootData } from '../../../../../../core/hooks/use-root-data';

import { EventOnPage, FilterValue, HeaderColumnGroupConfig } from './types';
import { StatsTableSettings } from './components/settings';

import { Loader } from '../../../../common';
import { SettingButton } from './components/settings/settings-modal';
import css from './index.module.css';
import cssColumns from './components/columns/index.module.css';

const filterFormatter = (filterMap: FilterValue) => {
  const filterKeys = Object.keys(filterMap);
  if (filterKeys.length) {
    const formattedFilter = filterKeys.reduce((acc, filter) => {
      if (
        filterMap[filter] &&
        filterMap[filter] !== '0' &&
        filterMap[filter] !== ''
      ) {
        return [...acc, { id: filter, value: filterMap[filter] }];
      }
      return acc;
    }, [] as any[]);
    return formattedFilter;
  }
  return [];
};

const StatisticsMK: React.FC = () => {
  const {
    isLoading,
    fetchMkStat,
    initStatsColumnsConfig,
    heroes,
    leagues,
    statsTableData,
    statsColumnsConfig,
    leagueFilterValue,
    dateStartFilterValue,
    clidOppsFilterValue,
    resultFilterValue,
    roundsFilterValues,
    coefRoundFilterValue,
    coefRfbFilterValue,
    durationsFilterValue,
    timeMinFilterValue,
    timeAvgFilterValue,
    timeMaxFilterValue,
    coefTotalFFilterValue,
    coefTotalBFilterValue,
    commonTotalFilterValue,
    coef1x2FilterValue,
    fwFilterValue,
    isMobile
  } = useRootData((store) => ({
    isLoading: store.mkStatisticsStore.isLoading,
    fetchMkStat: store.mkStatisticsStore.fetchMkStat,
    initStatsColumnsConfig: store.mkStatisticsStore.initStatsColumnsConfig,
    heroes: store.mkMainStore.oppHeroesDictionary,
    leagues: store.mkMainStore.oppLeagueDictionary,
    statsTableData: store.mkStatisticsStore.statsTableData,
    statsColumnsConfig: store.mkStatisticsStore.statsColumnsConfig,
    leagueFilterValue: store.mkStatisticsStore.leagueFilterValue,
    dateStartFilterValue: store.mkStatisticsStore.dateStartFilterValue,
    clidOppsFilterValue: store.mkStatisticsStore.clidOppsFilterValue,
    resultFilterValue: store.mkStatisticsStore.resultFilterValue,
    roundsFilterValues: store.mkStatisticsStore.roundsFilterValues,
    coefRoundFilterValue: store.mkStatisticsStore.coefRoundFilterValue,
    coefRfbFilterValue: store.mkStatisticsStore.coefRfbFilterValue,
    durationsFilterValue: store.mkStatisticsStore.durationsFilterValue,
    timeMinFilterValue: store.mkStatisticsStore.timeMinFilterValue,
    timeAvgFilterValue: store.mkStatisticsStore.timeAvgFilterValue,
    timeMaxFilterValue: store.mkStatisticsStore.timeMaxFilterValue,
    coefTotalFFilterValue: store.mkStatisticsStore.coefTotalFFilterValue,
    coefTotalBFilterValue: store.mkStatisticsStore.coefTotalBFilterValue,
    commonTotalFilterValue: store.mkStatisticsStore.commonTotalFilterValue,
    coef1x2FilterValue: store.mkStatisticsStore.coef1x2FilterValue,
    fwFilterValue: store.mkStatisticsStore.fwFilterValue,
    isMobile: store.mainStore.isMobile
  }));

  const { pagesCount, rowsCount, rows: data } = statsTableData;

  const [{ page, first, rows }, setPaginatorSettings] = useState({
    page: 0,
    first: 0,
    rows: 10
  });

  useEffect(() => {
    initStatsColumnsConfig({ heroes, leagues });
  }, [initStatsColumnsConfig, heroes, leagues]);

  useEffect(() => {
    fetchMkStat({
      page,
      pageSize: rows,
      sorted: [],
      filtered: [
        ...filterFormatter(leagueFilterValue),
        ...filterFormatter(dateStartFilterValue),
        ...filterFormatter(clidOppsFilterValue),
        ...filterFormatter(resultFilterValue),
        ...filterFormatter(roundsFilterValues),
        ...filterFormatter(coefRoundFilterValue),
        ...filterFormatter(coefRfbFilterValue),
        ...filterFormatter(durationsFilterValue),
        ...filterFormatter(timeMinFilterValue),
        ...filterFormatter(timeAvgFilterValue),
        ...filterFormatter(timeMaxFilterValue),
        ...filterFormatter(coefTotalFFilterValue),
        ...filterFormatter(coefTotalBFilterValue),
        ...filterFormatter(commonTotalFilterValue),
        ...filterFormatter(coef1x2FilterValue),
        ...filterFormatter(fwFilterValue)
      ]
    });
  }, [
    fetchMkStat,
    page,
    rows,
    dateStartFilterValue,
    leagueFilterValue,
    clidOppsFilterValue,
    resultFilterValue,
    roundsFilterValues,
    coefRoundFilterValue,
    coefRfbFilterValue,
    durationsFilterValue,
    timeMinFilterValue,
    timeAvgFilterValue,
    timeMaxFilterValue,
    coefTotalFFilterValue,
    coefTotalBFilterValue,
    commonTotalFilterValue,
    coef1x2FilterValue,
    fwFilterValue
  ]);

  const { headerColumnGroup, bodyColumnsElements } = useMemo(() => {
    if (statsColumnsConfig.length) {
      const colGroupParameters = statsColumnsConfig.reduce(
        (acc, { groupHeader, groupVisibility, columns, groupFilter }) => {
          if (groupVisibility) {
            acc[groupHeader] = {
              groupHeader,
              colSpan: columns.filter(({ visibility }) => visibility).length,
              groupFilter: groupFilter || undefined
            };
          }
          return acc;
        },
        {} as HeaderColumnGroupConfig
      );

      const headerColumnsElements = Object.entries(colGroupParameters).reduce(
        (acc, [groupHeader, { colSpan, groupFilter }]) => {
          if (colSpan > 0) {
            return [
              ...acc,
              <Column
                key={groupHeader}
                colSpan={colSpan}
                filter={!!groupFilter}
                filterElement={groupFilter}
                header={groupFilter ? null : groupHeader}
              />
            ];
          }
          return acc;
        },
        [] as JSX.Element[]
      );

      const columnsElements = statsColumnsConfig.reduce(
        (acc, { groupClassName, groupVisibility, columns }) =>
          groupVisibility
            ? [
                ...acc,
                ...columns.reduce(
                  (
                    accCol,
                    { header, field, width, visibility, renderer, filter }
                  ) =>
                    visibility
                      ? [
                          ...accCol,
                          <Column
                            key={field}
                            body={renderer}
                            className={
                              cssColumns[
                                groupClassName as keyof typeof cssColumns
                              ]
                            }
                            colSpan={1}
                            field={field}
                            filter={!!filter}
                            filterElement={filter || undefined}
                            header={filter ? null : header}
                            // TODO: переделать на класс
                            style={{ width, maxWidth: width, minWidth: width }}
                          />
                        ]
                      : accCol,
                  [] as JSX.Element[]
                )
              ]
            : acc,
        [] as JSX.Element[]
      );

      return {
        headerColumnGroup: (
          <ColumnGroup>
            <Row>{headerColumnsElements}</Row>
            <Row>{columnsElements}</Row>
          </ColumnGroup>
        ),
        bodyColumnsElements: columnsElements
      };
    }
    return {
      headerColumnGroup: [],
      bodyColumnsElements: []
    };
  }, [statsColumnsConfig]);

  const onPageChange = (e: EventOnPage) => setPaginatorSettings(e);

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <>
          <StatsTableSettings />
          <DataTable
            autoLayout
            lazy
            paginator
            scrollable
            className={cn(css.dataTable, {
              [css.dataTableHidden]: isLoading,
              [css.dataTableMobile]: isMobile
            })}
            currentPageReportTemplate={`Показана {currentPage} из ${
              !pagesCount ? '-' : pagesCount
            } `}
            first={first}
            headerColumnGroup={headerColumnGroup}
            loading={isLoading}
            onPage={onPageChange}
            paginatorLeft={isMobile && <SettingButton />}
            paginatorRight={isMobile && <SettingButton />}
            paginatorTemplate={
              !isMobile
                ? 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport'
                : 'PrevPageLink PageLinks NextPageLink RowsPerPageDropdown'
            }
            rows={rows}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            totalRecords={rowsCount}
            value={data}
          >
            {bodyColumnsElements}
          </DataTable>
        </>
      )}
    </>
  );
};

export default StatisticsMK;
