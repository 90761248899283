/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { InputSwitch } from 'primereact/inputswitch';
import { useRootData } from '../../../../../../../../core/hooks/use-root-data';

import css from './index.module.css';

const AutoUpdateSwitch = () => {
  const { autoUpdate, setAutoUpdate } = useRootData((store) => ({
    autoUpdate: store.mkStatisticsStore.autoUpdate,
    setAutoUpdate: store.mkStatisticsStore.setAutoUpdate
  }));

  const onChangeAutoUpdate = ({ value }: any) => setAutoUpdate(value);

  return (
    <>
      <div className={css.autoUpdateLabel}>Автообновление</div>
      <div className={css.autoUpdateSwitchLayout}>
        <InputSwitch checked={autoUpdate} onChange={onChangeAutoUpdate} />
      </div>
    </>
  );
};

export default AutoUpdateSwitch;
