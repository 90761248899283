/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { InputText } from 'primereact/inputtext';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

type CoefRoundFilterProps = {
  field: string;
};
const CoefRoundFilter = ({ field }: CoefRoundFilterProps) => {
  const { setCoefRoundFilterValue } = useRootData((store) => ({
    setCoefRoundFilterValue: store.mkStatisticsStore.setCoefRoundFilterValue
  }));

  const onCoefRoundFilterChange = (e: any) =>
    e.key === 'Enter' &&
    setCoefRoundFilterValue(`sp${field}`, e.target.value.trim());

  return (
    <InputText
      className={css.customInput}
      onKeyDown={onCoefRoundFilterChange}
      placeholder={`П${field}`}
    />
  );
};

export default CoefRoundFilter;
