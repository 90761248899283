import React from 'react';

import { Button } from 'primereact/button';
import { useRootData } from '../../../../../../../../core/hooks/use-root-data';
import InformationContent from './information-content';
import SettingsContent from './settings-content';
import SettingsSidebar from './settings-sidebar';
import css from './index.module.css';

const Settings: React.FC = () => {
  const { isShowSettings, setIsShowSettings } = useRootData((store) => ({
    isShowSettings: store.mkAnalisisStore.isShowSettings,
    setIsShowSettings: store.mkAnalisisStore.setIsShowSettings
  }));

  const handleShowInformation = () => setIsShowSettings('information');
  const handleShowSettings = () => setIsShowSettings('settings');

  return (
    <div className={css.settingsLayout}>
      <div className={css.settingsButtonsLayout}>
        <Button icon="pi pi-table" onClick={handleShowInformation} />
        <Button icon="pi pi-cog" onClick={handleShowSettings} />
      </div>
      {isShowSettings === 'settings' && (
        <SettingsSidebar title="Настройки анализа:">
          <SettingsContent />
        </SettingsSidebar>
      )}
      {isShowSettings === 'information' && (
        <SettingsSidebar title="Дополнительная информация:">
          <InformationContent />
        </SettingsSidebar>
      )}
    </div>
  );
};

export default Settings;
