import React from 'react';

import { RfbTable, TotalStat } from '../../tables';

const InformationContent: React.FC = () => {
  return (
    <>
      <TotalStat />
      <RfbTable />
    </>
  );
};

export default InformationContent;
