import React from 'react';

import { AvailableEntitiesType } from './types';
import {
  Fifa,
  Home,
  KillerInstinct,
  MortalKombat,
  Tekken,
  TwentyOne
} from './components/main/components';

export const availableEntities: AvailableEntitiesType = {
  'home': {
    title: 'Home',
    icon: 'home',
    subPaths: [],
    path: '/',
    exact: true,
    component: () => <Home />
  },
  'mortalKombat': {
    title: 'Mortal Kombat',
    icon: 'mortalKombat',
    subPaths: ['statistics', 'analisis'],
    path: '/mk',
    component: () => <MortalKombat />
  },
  'killerInstinct': {
    title: 'Killer Instinct',
    icon: 'killerInstinct',
    subPaths: ['statistics', 'analisis'],
    path: '/ki',
    component: () => <KillerInstinct />
  },
  'twentyOne': {
    title: '21',
    icon: 'twentyOne',
    subPaths: ['statistics'],
    path: '/twentyOne',
    component: () => <TwentyOne />
  },
  'fifa': {
    title: 'FIFA',
    icon: 'fifa',
    subPaths: ['statistics', 'toto'],
    path: '/fifa',
    component: () => <Fifa />
  },
  'tekken': {
    title: 'Tekken',
    icon: 'tekken',
    subPaths: ['statistics'],
    path: '/tekken',
    component: () => <Tekken />
  }
};
