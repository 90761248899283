/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';

import { AnalisisChart, MainTable, Settings } from './components';
import { useRootData } from '../../../../../../core/hooks/use-root-data';

import { Loader } from '../../../../common';
import css from './index.module.css';

const AnalisisMK: React.FC = () => {
  const { isLoading, fetchMkAnalisis } = useRootData((store) => ({
    isLoading: store.mkAnalisisStore.isLoading,
    fetchMkAnalisis: store.mkAnalisisStore.fetchMkAnalisis,
    dataForRfbTable: store.mkAnalisisStore.dataForRfbTable
  }));

  useEffect(() => {
    fetchMkAnalisis({ groupGraphSize: 5, lastGameCount: 0, opp1: 0, opp2: 0 });
  }, [fetchMkAnalisis]);

  return (
    <>
      <Loader isLoading={isLoading} />
      {!isLoading && (
        <div className={css.analisisLayout}>
          <AnalisisChart />
          <MainTable />
          <Settings />
        </div>
      )}
    </>
  );
};

export default AnalisisMK;
