/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { InputText } from 'primereact/inputtext';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

type DurationFilterProps = {
  field: string;
};
const DurationFilter = ({ field }: DurationFilterProps) => {
  const { setDurationsFilterValue } = useRootData((store) => ({
    setDurationsFilterValue: store.mkStatisticsStore.setDurationsFilterValue
  }));

  const onDurationFilterChange = (e: any) =>
    e.key === 'Enter' &&
    setDurationsFilterValue(`r${field}time`, e.target.value.trim() || '0');

  return (
    <InputText
      className={css.customInput}
      onKeyDown={onDurationFilterChange}
      placeholder={field}
    />
  );
};

export default DurationFilter;
