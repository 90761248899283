/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { InputText } from 'primereact/inputtext';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

type CoefTotalFFilterProps = {
  field: string;
  header: string;
};
const CoefTotalFFilter = ({ field, header }: CoefTotalFFilterProps) => {
  const { setCoefTotalFFilterValue } = useRootData((store) => ({
    setCoefTotalFFilterValue: store.mkStatisticsStore.setCoefTotalFFilterValue
  }));

  const onCoefTotalFFilterChange = (e: any) =>
    e.key === 'Enter' &&
    setCoefTotalFFilterValue(field, e.target.value.trim() || '0');

  return (
    <InputText
      className={css.customInput}
      onKeyDown={onCoefTotalFFilterChange}
      placeholder={header}
    />
  );
};

export default CoefTotalFFilter;
