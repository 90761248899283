/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import cn from 'classnames';

import { Link } from 'react-router-dom';
import { getSVG } from '../../../icons';

import css from '../index.module.css';

import { useRootData } from '../../../../core/hooks/use-root-data';
import Logo from '../logo-item';
import NavigationItems from '../nav-items';
import Theme from '../theme-item';

const SidebarMobile = () => {
  const { isShownMenu, triggerMenuCollapse } = useRootData((store) => ({
    isShownMenu: store.mainStore.isShownMenu,
    triggerMenuCollapse: store.mainStore.triggerMenuCollapse
  }));

  const handleTriggerMenuCollapse = () => triggerMenuCollapse();

  return (
    <>
      <nav className={css.sidebar}>
        <ul className={css.nav}>
          <>
            <li className={css.navItem}>
              <Link className={css.navLink} to="/">
                {getSVG('home')}
              </Link>
            </li>
            <Logo />
            <li className={css.navItem}>
              <a
                className={css.navLink}
                onClick={handleTriggerMenuCollapse}
                role="button"
                tabIndex={0}
                type="button"
              >
                {getSVG('bars')}
              </a>
            </li>
          </>
        </ul>
      </nav>
      <div
        className={cn(css.hamburgerMenu, {
          [css.menuInActive]: isShownMenu
        })}
      >
        <NavigationItems />
        <Theme />
      </div>
    </>
  );
};

export default SidebarMobile;
