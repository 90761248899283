import axios from 'axios';

import { MkAnalisisPayloadType, MkStatiscticsPayloadType } from './types';

const requestConfig = {
  headers: { 'IBOT': '1' }
};

export const getMkOpp = () => {
  return axios.post('/api/mk/opp', {}, requestConfig);
};

export const getMkStatistics = ({
  page,
  pageSize,
  sorted,
  filtered
}: MkStatiscticsPayloadType) => {
  return axios.post(
    '/api/mk/stat',
    { page, pageSize, sorted, filtered },
    requestConfig
  );
};

export const getMkAnalisis = ({
  groupGraphSize,
  lastGameCount,
  opp1,
  opp2
}: MkAnalisisPayloadType) => {
  return axios.post(
    '/api/mk/analiz',
    { groupGraphSize, lastGameCount, opp1, opp2 },
    requestConfig
  );
};
