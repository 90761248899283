/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

const fwOptions = [{ value: 'passed', label: `Был проход` }];

const FwGroupFilter = () => {
  const { filter, setFilter } = useRootData((store) => ({
    filter: store.mkStatisticsStore.fwGroupFilterValue,
    setFilter: store.mkStatisticsStore.setFwGroupFilterValue
  }));

  const onFilterChange = ({ value }: any) => setFilter(value);

  return (
    <Dropdown
      showClear
      appendTo={document.body}
      className={css.customDropdown}
      onChange={onFilterChange}
      options={fwOptions}
      placeholder="ЧП"
      value={filter}
    />
  );
};

export default FwGroupFilter;
