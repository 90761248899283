/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
// import cn from 'classnames';

import { Button } from 'primereact/button';
import { Sidebar } from 'primereact/sidebar';
// import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

// import css from './index.module.css';

const SettingButton = () => {
  // const {} = useRootData((store) => ({
  //   avgTableData: store.mkStatisticsStore.avgTableData,
  //   isMobile: store.mainStore.isMobile
  // }));

  const [visibleLeft, setVisibleLeft] = useState(false);

  const handleShowSidebar = () => setVisibleLeft(true);

  // TODO: сделть выезд слева таблицы с AVG, а справа выезд полного сайда с настройками
  return (
    <>
      <Sidebar
        // fullScreen
        // baseZIndex={1000}
        dismissable={false}
        modal={false}
        onHide={() => setVisibleLeft(false)}
        position="left"
        visible={visibleLeft}
      >
        <h1 style={{ fontWeight: 'normal' }}>Full Screen Sidebar</h1>
      </Sidebar>
      <Button
        className="p-button-raised"
        icon="pi pi-cog"
        onClick={handleShowSidebar}
      />
    </>
  );
};

export { SettingButton };
