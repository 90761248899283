/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';

import { Dropdown } from 'primereact/dropdown';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

const LeagueFilter = () => {
  const {
    leagueFilterValue,
    setLeagueFilterValue,
    oppLeagueDictionary
  } = useRootData((store) => ({
    leagueFilterValue: store.mkStatisticsStore.leagueFilterValue,
    setLeagueFilterValue: store.mkStatisticsStore.setLeagueFilterValue,
    oppLeagueDictionary: store.mkMainStore.oppLeagueDictionary
  }));

  const leagueOptions = useMemo(() => {
    const options = Object.entries(oppLeagueDictionary).map(([key, value]) => ({
      value: key,
      label: value
    }));

    options.push({ value: '0', label: 'Все' });
    return options;
  }, [oppLeagueDictionary]);

  const onLeagueFilterChange = ({ value }: any) => setLeagueFilterValue(value);

  return (
    <Dropdown
      appendTo={document.body} // fix filter
      className={css.customDropdown}
      onChange={onLeagueFilterChange}
      options={leagueOptions}
      placeholder="Лига"
      value={leagueFilterValue}
    />
  );
};

export default LeagueFilter;
