import React from 'react';

import css from './index.module.css';

const Accaunt = () => {
  return (
    <div className={css.accauntLayout}>
      <div className={css.accaunt}>A</div>
    </div>
  );
};

export default Accaunt;
