/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

type RoundOptionType = {
  value: string;
  label: string;
};

const roundOptions: RoundOptionType[] = [
  { value: '0', label: '' },
  { value: '1', label: 'R' },
  { value: '2', label: 'F' },
  { value: '3', label: 'B' },
  { value: '10', label: 'П1' },
  { value: '11', label: 'П1 R' },
  { value: '12', label: 'П1 F' },
  { value: '13', label: 'П1 B' },
  { value: '20', label: 'П2' },
  { value: '21', label: 'П2 R' },
  { value: '22', label: 'П2 F' },
  { value: '23', label: 'П2 B' }
];

type RoundFilterProps = {
  field: string;
};

const RoundFilter = ({ field }: RoundFilterProps) => {
  const { roundsFilterValues, setRoundsFilterValues } = useRootData(
    (store) => ({
      roundsFilterValues: store.mkStatisticsStore.roundsFilterValues,
      setRoundsFilterValues: store.mkStatisticsStore.setRoundsFilterValues
    })
  );

  const onRoundFilterChange = ({ value: filterValue }: any) =>
    setRoundsFilterValues(field, filterValue);

  return (
    <Dropdown
      showClear
      appendTo={document.body}
      className={css.customDropdown}
      onChange={onRoundFilterChange}
      options={roundOptions}
      placeholder={field.replace('r', '')}
      value={roundsFilterValues[field]}
    />
  );
};

export default RoundFilter;
