/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import round from 'lodash.round';

import { AvgData, ColumnGroup, StatsTableData } from '../types';

import { DisablabledColumn } from '../../../common/types';
import { MkStatiscticsPayloadType } from '../../api/types';
import { getMkStatistics } from '../../api';
import { getStatsColumnsConfig } from '../components/columns';

export const createMkStatiscticsStore = () => ({
  isLoading: true,
  setLoading(value: boolean) {
    this.isLoading = value;
  },

  autoUpdate: false,
  setAutoUpdate(value: boolean) {
    this.autoUpdate = value;
  },

  currentDisabledColumns: [] as DisablabledColumn[],
  setCurrentDisabledColumns(value: DisablabledColumn[]) {
    this.currentDisabledColumns = value;
  },

  statsColumnsConfig: [] as ColumnGroup[],
  setStatsColumnsConfig(config: ColumnGroup[]) {
    this.statsColumnsConfig = config;
  },

  initStatsColumnsConfig({ heroes, leagues }: any) {
    this.setStatsColumnsConfig(getStatsColumnsConfig(heroes, leagues));
  },

  avgTableData: [
    {
      r: '-',
      rp1: '-',
      rp2: '-',
      f: '-',
      fp1: '-',
      fp2: '-',
      b: '-',
      bp1: '-',
      bp2: '-',
      time: '-'
    }
  ] as AvgData[],
  setAvgTableData(value: any) {
    const { db, db1, df, df1, dr, dr1, rc1, rc2, time } = value;
    const dr2 = dr - dr1;
    const df2 = df - df1;
    const db2 = db - db1;
    const data = [
      {
        r: dr ? round((rc1 + rc2) / dr, 1) : 0,
        rp1: dr1 ? round(rc1 / dr1, 1) : 0,
        rp2: dr2 ? round(rc2 / dr2, 1) : 0,

        f: df ? round((rc1 + rc2) / df, 1) : 0,
        fp1: df1 ? round(rc1 / df1, 1) : 0,
        fp2: df2 ? round(rc2 / df2, 1) : 0,

        b: db ? round((rc1 + rc2) / db, 1) : 0,
        bp1: db1 ? round(rc1 / db1, 1) : 0,
        bp2: db2 ? round(rc2 / db2, 1) : 0,
        time
      }
    ];

    this.avgTableData = data;
  },

  statsTableData: {} as StatsTableData,
  setStatsTableData(value: StatsTableData) {
    this.statsTableData = value;
  },

  fetchMkStat({ page, pageSize, sorted, filtered }: MkStatiscticsPayloadType) {
    try {
      this.setLoading(true);
      getMkStatistics({
        page,
        pageSize,
        sorted,
        filtered
      }).then(({ data }) => {
        const { pages: pagesCount, rows, rowsCount, avgStat } = data;
        console.log('data', data);
        this.setStatsTableData({
          rows,
          rowsCount,
          pagesCount
        });
        this.setAvgTableData(avgStat);
        this.setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  },

  // БЭКЕНДОВАЯ ФИЛЬТРАЦИЯ ПО КОНКРЕТНЫМ КОЛОНКАМ (Лига, датаб раунды и т.д.):
  leagueFilterValue: { 'champId': '1252965' } as { [key: string]: string },
  setLeagueFilterValue(value: string) {
    this.leagueFilterValue = { 'champId': value };
  },

  dateStartFilterValue: {} as { [key: string]: string },
  setDateStartFilterValue(value: string) {
    this.dateStartFilterValue = { 'dateStart': value };
  },

  resultFilterValue: {} as { [key: string]: string },
  setResultFilterValue(value: string) {
    this.resultFilterValue = { 'result': value };
  },

  clidOppsFilterValue: {} as { [key: string]: string },
  setClidOppFilterValue(field: string, value: string) {
    this.clidOppsFilterValue = { ...this.clidOppsFilterValue, [field]: value };
  },

  roundsFilterValues: {} as { [key: string]: string },
  setRoundsFilterValues(field: string, value: string) {
    this.roundsFilterValues = { ...this.roundsFilterValues, [field]: value };
  },

  coefRoundFilterValue: {} as { [key: string]: string },
  setCoefRoundFilterValue(field: string, value: string) {
    this.coefRoundFilterValue = {
      ...this.coefRoundFilterValue,
      [field]: value
    };
  },

  coefRfbFilterValue: {} as { [key: string]: string },
  setRfbFilterValue(field: string, value: string) {
    this.coefRfbFilterValue = {
      ...this.coefRfbFilterValue,
      [field]: value
    };
  },

  durationsFilterValue: {} as { [key: string]: string },
  setDurationsFilterValue(field: string, value: string) {
    this.durationsFilterValue = {
      ...this.durationsFilterValue,
      [field]: value
    };
  },

  timeMinFilterValue: {} as { [key: string]: string },
  setTimeMinFilterValue(field: string, value: string) {
    this.timeMinFilterValue = {
      ...this.timeMinFilterValue,
      [field]: value
    };
  },

  timeAvgFilterValue: {} as { [key: string]: string },
  setTimeAvgFilterValue(field: string, value: string) {
    this.timeAvgFilterValue = {
      ...this.timeAvgFilterValue,
      [field]: value
    };
  },

  timeMaxFilterValue: {} as { [key: string]: string },
  setTimeMaxFilterValue(field: string, value: string) {
    this.timeMaxFilterValue = {
      ...this.timeMaxFilterValue,
      [field]: value
    };
  },

  coefTotalFFilterValue: {} as { [key: string]: string },
  setCoefTotalFFilterValue(field: string, value: string) {
    this.coefTotalFFilterValue = {
      ...this.coefTotalFFilterValue,
      [field]: value
    };
  },

  coefTotalBFilterValue: {} as { [key: string]: string },
  setCoefTotalBFilterValue(field: string, value: string) {
    this.coefTotalBFilterValue = {
      ...this.coefTotalBFilterValue,
      [field]: value
    };
  },

  commonTotalFilterValue: {} as { [key: string]: string },
  setCommonTotalFilterValue(field: string, value: string) {
    this.commonTotalFilterValue = {
      ...this.commonTotalFilterValue,
      [field]: value
    };
  },

  coef1x2FilterValue: {} as { [key: string]: string },
  setCoef1x2FilterValue(field: string, value: string) {
    this.coef1x2FilterValue = {
      ...this.coef1x2FilterValue,
      [field]: value
    };
  },

  fwFilterValue: {} as { [key: string]: string },
  setFwFilterValue(field: string, value: string) {
    this.fwFilterValue = {
      ...this.fwFilterValue,
      [field]: value
    };
  },

  // ФРОНТОВЫЕ ФИЛЬТРЫ ДЛЯ COLUMN GROUP (Параб раунды и т.д.)
  // для корректной работы дропдаунов тип string | null
  pairGroupFilterValue: null as string | null,
  setPairGroupFilterValue(value: string) {
    this.pairGroupFilterValue = value;
  },

  roundsGroupFilterValue: null as string | null,
  setRoundsGroupFilterValue(value: string) {
    this.roundsGroupFilterValue = value;
  },

  coefRoundGroupFilterValue: null as string | null,
  setCoefRoundGroupFilterValue(value: string) {
    this.coefRoundGroupFilterValue = value;
  },

  durationGroupFilterValue: null as string | null,
  setDurationGroupFilterValue(value: string) {
    this.durationGroupFilterValue = value;
  },

  coefTotalFGroupFilterValue: null as string | null,
  setCoefTotalFGroupFilterValue(value: string) {
    this.coefTotalFGroupFilterValue = value;
  },

  coefTotalBGroupFilterValue: null as string | null,
  setCoefTotalBGroupFilterValue(value: string) {
    this.coefTotalBGroupFilterValue = value;
  },

  commonTotalGroupFilterValue: null as string | null,
  setcommonTotalGroupFilterValue(value: string) {
    this.commonTotalGroupFilterValue = value;
  },

  fwGroupFilterValue: null as string | null,
  setFwGroupFilterValue(value: string) {
    this.fwGroupFilterValue = value;
  }
});

export type MkStatisticsStoreType = ReturnType<typeof createMkStatiscticsStore>;
