/* eslint-disable no-console */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { MkAnalisisPayloadType } from '../../api/types';
import { RfbInfo, TotalStat } from '../types';
import {
  generateRfbInfo,
  getSummarizedAnaliz,
  setMoreAdditionalInfo
} from './helpers';
import { getMkAnalisis } from '../../api';

export const createMkAnalisisStore = () => ({
  isLoading: true,
  setLoading(value: boolean) {
    this.isLoading = value;
  },

  isShowSettings: '' as 'settings' | 'information' | '',
  setIsShowSettings(value: 'settings' | 'information' | '') {
    this.isShowSettings = value;
  },

  leftHero: null as string | null,
  setLeftHero(value: string) {
    this.leftHero = value;
  },
  rightHero: null as string | null,
  setRightHero(value: string) {
    this.rightHero = value;
  },
  switchHeroes() {
    const { leftHero } = this;
    const { rightHero } = this;
    this.rightHero = leftHero;
    this.leftHero = rightHero;
  },

  roundsCount: 0,
  setRoundsCount(value: number) {
    this.roundsCount = value;
  },

  startDate: new Date('2016-01-01'),
  setStartDate(value: Date) {
    this.startDate = value;
  },

  dataForChart: {} as any,
  setDataForChart(value: any) {
    this.dataForChart = value;
  },

  dataForTotalStatTable: {} as TotalStat,
  setDataForTotalStatTable(value: TotalStat[]) {
    const [totalStat] = value;
    this.dataForTotalStatTable = totalStat;
  },

  dataForRfbTable: [] as RfbInfo[],
  setDataForRfbTable(value: RfbInfo[]) {
    this.dataForRfbTable = value;
  },

  dataForMainTable: [] as RfbInfo[],
  setDataForMainTable(value: RfbInfo[]) {
    this.dataForMainTable = value;
  },

  fetchMkAnalisis({
    groupGraphSize,
    lastGameCount,
    opp1,
    opp2
  }: MkAnalisisPayloadType) {
    try {
      this.setLoading(true);
      getMkAnalisis({ groupGraphSize, lastGameCount, opp1, opp2 }).then(
        ({ data }) => {
          console.log('data', data);

          // TODO: загружать даные для графиков отдельным запросом.
          const {
            analiz,
            totalStat,
            graphAvgtime,
            graphAvgAut,
            graphDobiv,
            graphDobivAvg,
            graphTotal,
            graphAvgtimeAvg,
            graphTimes
          } = data;

          console.log('Время', {
            graphAvgtimeAvg,
            graphAvgtime
          });

          const reorganizedDataForSeries = (graphDataItem: any[]) => {
            return graphDataItem.map((pointData, index) => {
              return {
                point: index + 1,
                avg: pointData,
                movingAvg: graphAvgtimeAvg
              };
            });
          };

          const kek = reorganizedDataForSeries(graphAvgtime);
          console.log('kek', kek);

          this.setDataForChart({
            totalData: kek
            // graphAvgtime,
            // graphAvgAut,
            // graphDobiv,
            // graphDobivAvg,
            // graphTotal,
            // graphAvgtimeAvg,
            // graphTimes
          });

          this.setDataForTotalStatTable(totalStat);

          const summarizedAnaliz = getSummarizedAnaliz(analiz);

          const rfbInfo = generateRfbInfo(summarizedAnaliz);
          this.setDataForRfbTable([rfbInfo]);

          const analizDataWithSumm = setMoreAdditionalInfo([
            ...analiz,
            summarizedAnaliz
          ]);
          this.setDataForMainTable(analizDataWithSumm);

          // TODO: set user stuff

          this.setLoading(false);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }
});

export type MkAnalisisStoreType = ReturnType<typeof createMkAnalisisStore>;
