/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

const pairOptions = [
  { value: 'winner', label: 'Победитель' },
  { value: 'fatality', label: 'Фаталити' },
  { value: 'brutality', label: 'Бруталити' }
];

const PairGroupFilter = () => {
  const { pairFilterValue, setPairFilterValue } = useRootData((store) => ({
    pairFilterValue: store.mkStatisticsStore.pairGroupFilterValue,
    setPairFilterValue: store.mkStatisticsStore.setPairGroupFilterValue
  }));

  const onPairFilterChange = ({ value }: any) => setPairFilterValue(value);

  return (
    <Dropdown
      showClear
      appendTo={document.body}
      className={css.customDropdown}
      onChange={onPairFilterChange}
      options={pairOptions}
      placeholder="Пара"
      value={pairFilterValue}
    />
  );
};

export default PairGroupFilter;
