/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { InputText } from 'primereact/inputtext';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

const DateStartFilter = () => {
  const { setDateStartFilterValue } = useRootData((store) => ({
    setDateStartFilterValue: store.mkStatisticsStore.setDateStartFilterValue
  }));

  const onDateStartFilterChange = (e: any) =>
    e.key === 'Enter' && setDateStartFilterValue(e.target.value.trim());

  //   <Calendar value={date8} onChange={(e) => setDate8(e.value)} showTime={true} showSeconds={true} />

  return (
    <InputText
      className={css.customInput}
      onKeyDown={onDateStartFilterChange}
      placeholder="Дата и время"
    />
  );
};

export default DateStartFilter;
