/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { Row } from 'primereact/row';

import { AvgData } from '../../mortalKombat/statistics/types';
import { AvgTableColGroupParameters, AvgTableColumnConfigItem } from '../types';

import css from './index.module.css';

type AverageTableProps = {
  data: AvgData[];
  columnsConfig: AvgTableColumnConfigItem[];
};

const AverageTable = ({ data, columnsConfig }: AverageTableProps) => {
  const columnsElements = columnsConfig.flatMap(({ columns }) => {
    return columns.map(({ header, field, width, renderer }) => (
      <Column
        key={field}
        body={renderer}
        className={css.avgTableHeaderCell}
        field={field}
        header={header}
        style={{ width, maxWidth: width, minWidth: width }}
      />
    ));
  });

  const colGroupParameters = columnsConfig.reduce(
    (acc, { groupHeader, columns }) => {
      acc[groupHeader] = columns.length;
      return acc;
    },
    {} as AvgTableColGroupParameters
  );

  const headerColumnsElements = Object.entries(colGroupParameters).reduce(
    (acc, [groupHeader, colSpan]) => {
      return [
        ...acc,
        <Column
          key={groupHeader}
          className={css.avgTableHeaderCell}
          colSpan={colSpan}
          header={groupHeader}
        />
      ];
    },
    [] as JSX.Element[]
  );

  const headerColumnGroup = (
    <ColumnGroup>
      <Row>{headerColumnsElements}</Row>
      <Row>{columnsElements}</Row>
    </ColumnGroup>
  );

  return (
    <div className={css.averageTable}>
      <DataTable
        autoLayout
        headerColumnGroup={headerColumnGroup}
        paginator={false}
        value={data}
      >
        {columnsElements}
      </DataTable>
    </div>
  );
};

export default AverageTable;
