/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import { Dropdown } from 'primereact/dropdown';
import { useRootData } from '../../../../../../../../../core/hooks/use-root-data';

import css from '../index.module.css';

type ClidOppsFilterProps = {
  field: string;
  header: string;
};
const ClidOppsFilter = ({ field, header }: ClidOppsFilterProps) => {
  const {
    clidOppsFilterValue,
    setClidOppFilterValue,
    heroesOptions
  } = useRootData((store) => ({
    clidOppsFilterValue: store.mkStatisticsStore.clidOppsFilterValue,
    setClidOppFilterValue: store.mkStatisticsStore.setClidOppFilterValue,
    heroesOptions: store.mkMainStore.heroesOptions
  }));

  const onClidOppsFilterChange = ({ value }: any) =>
    setClidOppFilterValue(field, value);

  return (
    <Dropdown
      filter
      showClear
      appendTo={document.body}
      className={css.customDropdown}
      filterBy="label"
      filterPlaceholder="Поиск героя"
      onChange={onClidOppsFilterChange}
      options={heroesOptions}
      placeholder={header}
      value={clidOppsFilterValue[field]}
    />
  );
};

export default ClidOppsFilter;
