/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';

import css from './index.module.css';

import { useRootData } from '../../../../../../../../core/hooks/use-root-data';

const CoefTotalBRenderer = ({ rowData, field }: any) => {
  const { filter } = useRootData((store) => ({
    filter: store.mkStatisticsStore.coefTotalBGroupFilterValue
  }));

  const coefTotalBValue = rowData[field].v;

  const isPassed = () => {
    if (filter !== 'passed' || coefTotalBValue === 0) return false;

    const totalB = [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce((result, roundIndex) => {
      if (rowData[`r${roundIndex}`] && rowData[`r${roundIndex}`].dobiv === 'B')
        return result + 1;
      return result;
    }, 0);

    const val =
      Number(field.replace('tb', '').replace('m', '').replace('b', '')) / 10;
    const subField = field.replace('tb', '');
    if (subField.includes('m') && totalB < val) return true;
    if (subField.includes('b') && totalB > val) return true;

    return false;
  };

  const coefTotalBClass = cn(css.dataTableTag, {
    [css.coefTotalB_passed]: isPassed()
  });

  return <div className={coefTotalBClass}>{coefTotalBValue}</div>;
};

export default CoefTotalBRenderer;
