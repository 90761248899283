/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';

import css from './index.module.css';

import { useRootData } from '../../../../../../../../core/hooks/use-root-data';

const DurationRenderer = ({ rowData, field }: any) => {
  const { filter } = useRootData((store) => ({
    filter: store.mkStatisticsStore.durationGroupFilterValue
  }));

  const durationValue = rowData[field].v || '-';

  const coefRoundClass = cn(css.dataTableTag, {
    [css.duration_min_avg]:
      (filter?.includes('min') || filter?.includes('avg')) &&
      rowData.timep2m &&
      rowData.timep2m.v &&
      durationValue < rowData.timep2m.v,
    [css.duration_min]:
      filter?.includes('min') &&
      rowData.timep1m &&
      rowData.timep1m.v &&
      durationValue < rowData.timep1m.v,
    [css.duration_max_avg]:
      (filter?.includes('max') || filter?.includes('avg')) &&
      rowData.timep2m &&
      rowData.timep2m.v &&
      durationValue > rowData.timep2m.v,
    [css.duration_max]:
      filter?.includes('max') &&
      rowData.timep3m &&
      rowData.timep3m.v &&
      durationValue > rowData.timep3m.v
  });

  return <div className={coefRoundClass}>{durationValue}</div>;
};

export default DurationRenderer;
