import React from 'react';
import cn from 'classnames';

import { Link, useLocation } from 'react-router-dom';
import css from '../index.module.css';

import { availableEntities } from '../../../config';
import { getSVG } from '../../../icons';

const NavigationItems = () => {
  const { pathname } = useLocation();

  return (
    <>
      {Object.keys(availableEntities).map((menuKey: string) => {
        const { title, icon, path, subPaths } = availableEntities[menuKey];

        /**
         * Not cool check for main path ('/')
         */
        const isActive =
          pathname === '/'
            ? pathname === path
            : subPaths.some((subPath) =>
                `${path}/${subPath}`.includes(pathname)
              );

        return (
          <li key={title} className={css.navItem}>
            <Link
              className={cn(css.navLink, isActive && css.navLinkActive)}
              to={path}
            >
              {getSVG(icon)}
              <span className={css.linkText}>{title}</span>
            </Link>
          </li>
        );
      })}
    </>
  );
};

export default NavigationItems;
