/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import cn from 'classnames';

import css from './index.module.css';

import { useRootData } from '../../../../../../../../core/hooks/use-root-data';

const FwRenderer = ({ rowData, field }: any) => {
  const { filter } = useRootData((store) => ({
    filter: store.mkStatisticsStore.fwGroupFilterValue
  }));

  const fwValue = rowData[field].v;

  const isPassed = () => {
    if (filter !== 'passed' || field === 'scp') return false;

    const fwDo = [1, 2, 3, 4, 5, 6, 7, 8, 9].reduce((result, index) => {
      if (rowData[`r${index}`] && rowData[`r${index}`].wt) return true;
      return result;
    }, false);

    if (fwDo) return true;

    return false;
  };

  const fwClass = cn(css.dataTableTag, {
    [css.fw_passed]: isPassed()
  });

  return <div className={fwClass}>{fwValue}</div>;
};

export default FwRenderer;
