/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import round from 'lodash.round';

import { AnalizRound, RfbInfo } from '../types';

const getSummarizedAnaliz = (analiz: AnalizRound[]) =>
  analiz.reduce((sumAcc: AnalizRound, roundItem: AnalizRound) => {
    Object.keys(roundItem).forEach((key) => {
      if (!sumAcc[key]) sumAcc[key] = 0;
      sumAcc[key] = +sumAcc[key] + +roundItem[key];
    });
    sumAcc.round = 'Сумма';
    return { ...sumAcc }; // replace , round: 'Сумма'
  }, {} as AnalizRound);

const generateRfbInfo = (data: RfbInfo) => ({
  r:
    data.rTotal && data.rTotal > 0
      ? round((+data.p1 + +data.p2) / +data.rTotal, 1)
      : 0,
  rp1: data.rP1 ? round(+data.p1 / +data.rP1, 1) : 0,
  rp2: data.rP2 ? round(+data.p2 / +data.rP2, 1) : 0,
  f:
    data.fTotal && data.fTotal > 0
      ? round((+data.p1 + +data.p2) / +data.fTotal, 1)
      : 0,
  fp1: data.fP1 ? round(+data.p1 / +data.fP1, 1) : 0,
  fp2: data.fP2 ? round(+data.p2 / +data.fP2, 1) : 0,
  b:
    data.bTotal && data.bTotal > 0
      ? round((+data.p1 + +data.p2) / +data.bTotal, 1)
      : 0,
  bp1: data.bP1 ? round(+data.p1 / +data.bP1, 1) : 0,
  bp2: data.bP2 ? round(+data.p2 / +data.bP2, 1) : 0
});

const setMoreAdditionalInfo = (rounds: AnalizRound[]) =>
  rounds.map((item: AnalizRound) => ({
    ...item,
    p1p: item.pTotal ? round((+item.p1 * 100) / +item.pTotal, 1) : 0,
    p2p: item.pTotal ? round((+item.p2 * 100) / +item.pTotal, 1) : 0,
    rTolalP: item.pTotal ? round((+item.rTotal * 100) / +item.pTotal, 1) : 0,
    rP1P: item.rTotal ? round((+item.rP1 * 100) / +item.rTotal, 1) : 0,
    rP2P: item.rTotal ? round((+item.rP2 * 100) / +item.rTotal, 1) : 0,
    fTolalP: item.pTotal ? round((+item.fTotal * 100) / +item.pTotal, 1) : 0,
    fP1P: item.fTotal ? round((+item.fP1 * 100) / +item.fTotal, 1) : 0,
    fP2P: item.fTotal ? round((+item.fP2 * 100) / +item.fTotal, 1) : 0,
    bTotalP: item.pTotal ? round((+item.bTotal * 100) / +item.pTotal, 1) : 0,
    bP1P: item.bTotal ? round((+item.bP1 * 100) / +item.bTotal, 1) : 0,
    bP2P: item.bTotal ? round((+item.bP2 * 100) / +item.bTotal, 1) : 0,
    timeA: item.timeC ? round(+item.timeS / +item.timeC, 1) : 0
  }));

export { getSummarizedAnaliz, generateRfbInfo, setMoreAdditionalInfo };
