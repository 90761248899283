// import { IntlProvider } from 'react-intl';
// import { flattenMessages } from './lib/flattenMessages';
// import { locale, localeDictionary } from './locales';

import React from 'react';
import ReactDOM from 'react-dom';

import { StoreProvider } from './features/core';
import Router from './Router';

import 'mobx-react-lite/batchingForReactDom';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/nova-light/theme.css';

ReactDOM.render(
  // <IntlProvider
  //   defaultLocale="ru"
  //   locale={locale}
  //   messages={flattenMessages(localeDictionary)}
  // >
  <StoreProvider>
    <Router />
  </StoreProvider>,
  // </IntlProvider>,
  document.getElementById('root')
);
