import { Link, useLocation } from 'react-router-dom';
import React from 'react';
import cn from 'classnames';

import { PathsType } from '../../../types';
import { availableEntities } from '../../../config';
import css from '../index.module.css';

const NavigationSwitcher: React.FC = () => {
  const { pathname } = useLocation();

  const currentPaths = Object.keys(availableEntities).reduce(
    (acc: PathsType, entityKey: string) => {
      const { path, subPaths } = availableEntities[entityKey];
      return pathname.includes(path) ? { path, subPaths } : acc;
    },
    {} as PathsType
  );

  const { path, subPaths } = currentPaths;

  return (
    <div className={css.navigationSubRoutesLayout}>
      {subPaths.map((subPath) => {
        /**
         * Not cool check for default path (like '/mk')
         */
        const isActive =
          pathname === path
            ? subPaths.indexOf(subPath) === 0
            : pathname === `${path}/${subPath}`;

        return (
          <Link
            key={subPath}
            className={cn(css.subRouteItem, isActive && css.subRouteItemActive)}
            to={`${path}/${subPath}`}
          >
            {subPath.toUpperCase()}
          </Link>
        );
      })}
    </div>
  );
};

export default NavigationSwitcher;
